import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Box, IconButton, InputAdornment, ListItemButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import GooglePoweredBy from 'assets/svg/poweredByGoogle.svg';
import {
  GoogleAddressDescriptionWrapper,
  GoogleAddressFormatTitle,
  GoogleAddressPowerBy,
  GoogleAddressTitle,
  SearchContent,
  SearchInput,
} from 'components/UI/GoogleAddressAutoComplete/styles';

export interface GoogleAddressSearchProps {
  backgroundColor: string | undefined;
  setAddressInfo: (dataModel: any) => void;
  currentAddress: string;
  businessProfile: any;
  setAddressError: any;
}

export interface InterfaceAdddressInfo {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  state?: string;
  zipCode?: string;
}

const GoogleAddressAutoComplete = ({
  backgroundColor,
  setAddressInfo,
  currentAddress = '',
  businessProfile,
  setAddressError,
}: GoogleAddressSearchProps) => {
  const {
    ready,
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();
  const inputRef = useRef<HTMLInputElement>(null);

  const [show, setShow] = useState(false);

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    setShow(true);
    setAddressError('');
  };

  useEffect(() => {
    if (currentAddress) {
      setValue(currentAddress);
      setShow(false);
      clearSuggestions();
    }
  }, [currentAddress]);

  const handleSelect = async (val: string, secondItem: { secondary_text: string | any }): Promise<void> => {
    setValue(val, false);

    getGeocode({ address: val }).then((results: any) => {
      const { lat, lng } = getLatLng(results[0]);
      const addressComponents = results[0].address_components;
      const addressInfo: InterfaceAdddressInfo = {};

      addressComponents.forEach((component: any) => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number':
            if (component.long_name) {
              addressInfo.address1 = `${component.long_name} `;
            }
            break;
          case 'route':
            if (addressInfo.address1) {
              addressInfo.address1 += component.long_name;
            } else {
              addressInfo.address1 = component.long_name;
            }
            break;
          case 'subpremise':
            addressInfo.address2 = component.long_name;
            break;
          case 'country':
            addressInfo.country = component.long_name;
            break;

          case 'locality':
            addressInfo.city = component.long_name;
            break;
          case 'administrative_area_level_1':
            addressInfo.state = component.long_name;
            break;
          case 'postal_code':
            addressInfo.zipCode = component.long_name;
            break;
          default:
            break;
        }
      });
      const dataModel = {
        ...addressInfo,
        fullAddress: val,
        geometry: { lat: lat, lng: lng },
        geoLocation: {
          lat: lat,
          lng: lng,
          debug: results[0],
        },
        formattedAddress: secondItem?.secondary_text,
        isDefault: true,
        id: uuidv4(),
      };
      setAddressInfo(dataModel);
    });

    clearSuggestions();
  };

  const clearValue = () => {
    setValue('');
    businessProfile.setValue('address', '');
    setAddressError('The field is required');
  };

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map((data: any) => {
      const { place_id, description, structured_formatting } = data;
      return (
        <Box key={place_id}>
          <ListItemButton onClick={() => handleSelect(description, structured_formatting)}>
            <GoogleAddressDescriptionWrapper disablePadding={true}>
              <GoogleAddressTitle>{description}</GoogleAddressTitle>
              {structured_formatting?.secondary_text && (
                <GoogleAddressFormatTitle>{structured_formatting?.secondary_text}</GoogleAddressFormatTitle>
              )}
            </GoogleAddressDescriptionWrapper>
          </ListItemButton>
        </Box>
      );
    });
    return (
      <Box>
        <SearchContent>
          {suggestions}
          <GoogleAddressPowerBy>
            <img src={GooglePoweredBy} alt="google" width={110} height={16} />
          </GoogleAddressPowerBy>
        </SearchContent>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        borderRadius: '8px',
        width: '100%',
      }}
    >
      <SearchInput
        inputRef={inputRef}
        size="small"
        value={value}
        onChange={handleInput}
        label={'Location'}
        disabled={!ready}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {value.length ? (
                <IconButton size={'small'} onClick={clearValue}>
                  <CloseIcon />
                </IconButton>
              ) : (
                ''
              )}
            </InputAdornment>
          ),

          sx: {
            width: '100%',
            background: backgroundColor,
          },
        }}
      />

      {status === 'OK' && show && renderSuggestions()}
    </Box>
  );
};

export default GoogleAddressAutoComplete;
