import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features';

interface ErrorState {
  showError: boolean;
  errorMessage: string;
  errorTitle: string;
}

const initialState: ErrorState = {
  showError: false,
  errorMessage: '',
  errorTitle: '',
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<{ message: string; title: string }>) => {
      state.showError = true;
      state.errorMessage = action.payload.message;
      state.errorTitle = action.payload.title;
    },
    clearError: (state) => {
      state.showError = false;
      state.errorMessage = '';
      state.errorTitle = '';
    },
  },
});

export const errorSelector = {
  showError: (state: RootState) => state.error.showError,
  errorMessage: (state: RootState) => state.error.errorMessage,
  errorTitle: (state: RootState) => state.error.errorTitle,
};

export const { setError, clearError } = errorSlice.actions;
export default errorSlice;
