import React from 'react';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

interface Props {
  duration: number;
  onTimerEnd: () => void;
  resetDuration: boolean;
}

const CountdownTimer: React.FC<Props> = ({ duration, onTimerEnd, resetDuration }) => {
  const [remainingTime, setRemainingTime] = useState<number>(duration);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [remainingTime]);

  useEffect(() => {
    setRemainingTime(duration);
  }, [duration, resetDuration]);

  useEffect(() => {
    if (remainingTime === 0) {
      onTimerEnd();
    }
  }, [remainingTime]);

  const minutes = Math.max(Math.floor(remainingTime / 60), 0)
    .toString()
    .padStart(2, '0');
  const seconds = Math.max(remainingTime % 60, 0)
    .toString()
    .padStart(2, '0');

  if (remainingTime === 0) {
    return <></>;
  }

  return (
    <Typography sx={{ fontSize: '16px' }} fontWeight={'bold'}>
      {minutes}:{seconds}
    </Typography>
  );
};

export default CountdownTimer;
