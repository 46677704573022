import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, MenuItem, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledForm } from 'pages/Authenticate/styles';
import { MenuTitle } from 'pages/BusinessProfile/styles';
import LoadingButton from 'components/UI/LoadingButton';
import { configSelectors, configSlice } from 'features/config';
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { StyledFooter } from 'components/UI/LoadingButton/styles';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { businessSchema } from 'pages/BusinessProfile/businessSchema';
import {
  useGetBusinessQuery,
  useGetSettingsQuery,
  useLazyUploadPictureQuery,
  useUpdateSettingsMutation,
  useCreateBusinessMutation,
} from 'api/index';
import PreviewImage from 'components/ImageUpload/PreviewImage';
import GoogleAddressAutoComplete from 'components/UI/GoogleAddressAutoComplete/GoogleAddressAutoComplete';
import EditBusinessProfile from './EditBusinessProfile';

export enum IBusinessMode {
  EDIT = 'edit',
  CREATE = 'create',
}

const BusinessProfile = () => {
  const dispatch = useAppDispatch();
  const currenciesList = useAppSelector(configSelectors.currencies);
  const haveFormChange = useAppSelector(configSelectors.haveFormChange);
  const { data: businessData, isLoading: isGetBusinessLoading } = useGetBusinessQuery(null);
  const [createBusiness, { isLoading: isBusinessLoading }] = useCreateBusinessMutation();
  const { data: settings } = useGetSettingsQuery(undefined);
  const [mode, setMode] = useState<string | any>('');
  const [uploadImage, { isFetching: isImageLoading }] = useLazyUploadPictureQuery();
  const [updateSettings, { isLoading: isUpdateCurrenciesLoading }] = useUpdateSettingsMutation();
  const [addressError, setAddressError] = useState<string>('');
  const [images, setImages] = useState<string | any>('');
  const [imageURLs, setImageURLs] = useState<string | any>('');
  const [addressTouched, setAddressTouched] = useState<boolean | any>(false);
  const [imageIsTouched, setImageIsTouched] = useState<boolean>(false);

  const businessProfile = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      logo: '',
      phoneNumber: '',
      address: '',
      description: '',
      currency: '',
    },
    resolver: yupResolver(businessSchema),
  });
  const { reset, getValues } = businessProfile;

  useEffect(() => {
    if (businessData && businessData?.business && currenciesList && currenciesList.length) {
      setMode(IBusinessMode.EDIT);
    } else {
      setMode(IBusinessMode.CREATE);
    }
  }, [businessData?.business, settings, currenciesList]);

  const handleUploadImagePreview = (event: any) => {
    if (!event.target.files) return;
    setImages(event.target.files[0]);
  };

  useEffect(() => {
    if (currenciesList.length && !businessData) {
      const defaultCurrecy = currenciesList.find((item) => item.currencyCode === 'USD');
      reset({ ...getValues(), currency: defaultCurrecy.id });
    }
  }, [currenciesList]);

  useEffect(() => {
    if (images) {
      setImageIsTouched(true);
      setImageURLs(URL.createObjectURL(images));
    }
  }, [images]);

  const handleDelete = () => {
    setImages('');
    setImageURLs('');
    localStorage.removeItem('fileId');
  };

  const setAddressInfo = (dataModel: any) => {
    if (dataModel) {
      setAddressError('');
      businessProfile.setValue('address', dataModel?.fullAddress);
      setAddressTouched(true);
    }
  };

  const isFormDirty = businessProfile.formState.isDirty || addressTouched;
  const handleSubmit = async (values: any) => {
    if (!values?.address) {
      setAddressError('The field is required');
      return;
    }
    if (typeof values.phoneNumber !== 'number') {
      values['formattedPhoneNumber'] = values.phoneNumber;
    }
    dispatch(configSlice.actions.formChangeWatcher(false));
    setAddressError('');
    let imageData: any;
    if (images) {
      imageData = await handleUploadImage(images);
      localStorage.setItem('fileId', imageData?.data?.logo?.fileId);
    }
    try {
      await createBusiness({
        ...values,
        name: values.name,
        logo: imageData?.data ?? null,
        address: values.address,
        phoneNumber: values.phoneNumber.replace(/[^+\d]/g, ''),
        description: values.description,
      }).unwrap();
      await updateSettings({
        websiteMode: settings?.websiteMode ?? 'light',
        menuCurrencyId: values.currency,
      }).unwrap();
    } catch (error) {
      console.log('Error:', error);
    }
  };

  useEffect(() => {
    if (!haveFormChange && businessProfile.formState.isDirty) {
      dispatch(configSlice.actions.formChangeWatcher(businessProfile.formState.isDirty));
    }
    if (imageIsTouched) {
      dispatch(configSlice.actions.formChangeWatcher(imageIsTouched));
    }
    if (addressTouched) {
      dispatch(configSlice.actions.formChangeWatcher(addressTouched));
    }
  }, [businessProfile.formState.isDirty, imageIsTouched, addressTouched]);

  const handleUploadImage = async (image: any) => {
    const formData = new FormData();
    formData.append('file', image);
    try {
      return await uploadImage(formData);
    } catch (error) {
      throw new Error('Error on image upload');
    }
  };
  const handleError = () => {
    let address = businessProfile.getValues('address');
    if (!address) {
      setAddressError('The field is required');
    } else {
      setAddressError('');
    }
  };
  return (
    <>
      {mode === IBusinessMode.CREATE ? (
        <FormProvider {...businessProfile}>
          <StyledForm onSubmit={businessProfile.handleSubmit(handleSubmit, handleError)}>
            {isGetBusinessLoading && !businessData ? (
              <Box
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'calc(100vh - 74px - 84px)',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  opacity: 1,
                  height: '100%',
                  flexDirection: 'column',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ width: '100%', maxWidth: '454px', p: 3 }}>
                  <MenuTitle>Business information</MenuTitle>
                  {imageURLs ? (
                    <PreviewImage imageUrl={imageURLs} handleDelete={handleDelete} />
                  ) : (
                    <ImageUpload handleUploadImage={handleUploadImagePreview} />
                  )}
                  <CustomTextInput size="small" name="name" label="Restaurant name" />
                  <CustomTextInput size="small" name="description" label="Description" multiline={true} rows={3} />
                  <CustomTextInput size="small" name="phoneNumber" label="Phone Number" isRef={true} />
                  <Box sx={{ mb: 1 }}>
                    <GoogleAddressAutoComplete
                      backgroundColor="#F4F4F4"
                      setAddressInfo={setAddressInfo}
                      currentAddress={businessProfile.getValues('address')}
                      businessProfile={businessProfile}
                      setAddressError={setAddressError}
                    />
                    {addressError && <Typography sx={{ color: 'red', fontSize: '12px' }}>{addressError}</Typography>}
                  </Box>
                  <CustomTextInput size="small" name="currency" label="Menu currency" select={true}>
                    {currenciesList.length &&
                      currenciesList.map((currencyItem: any, index: number) => {
                        return (
                          <MenuItem value={currencyItem?.id} key={`${currencyItem?.id}-${index}`} selected={true}>
                            {currencyItem?.currencyCode}
                          </MenuItem>
                        );
                      })}
                  </CustomTextInput>
                  <StyledFooter>
                    <LoadingButton
                      isLoading={isImageLoading || isBusinessLoading || isUpdateCurrenciesLoading}
                      sx={{ width: '454px' }}
                      type={'submit'}
                      variant={'contained'}
                      disabled={
                        Boolean(isBusinessLoading || isUpdateCurrenciesLoading || isImageLoading) ||
                        (!isFormDirty && !imageIsTouched)
                      }
                    >
                      Save
                    </LoadingButton>
                  </StyledFooter>
                </Box>
              </Box>
            )}
          </StyledForm>
        </FormProvider>
      ) : (
        <>
          {mode === IBusinessMode.EDIT && isGetBusinessLoading && !businessData ? (
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 74px - 84px)',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <EditBusinessProfile businessData={businessData} currenciesList={currenciesList} settings={settings} />
          )}
        </>
      )}
    </>
  );
};

export default BusinessProfile;
