import React from "react";
import {Fab} from "@mui/material";
import ImageUploadIcon from "assets/img/exclusion/Exclusion.png";
import {StyledFileField, StyledImageWrapper, StyledInputLabel} from "components/ImageUpload/styles";

export interface IImageUploadPagePros {
  handleUploadImage: (e: any) => void;
}

export const ImageUpload = ({ handleUploadImage }: IImageUploadPagePros) => {
  return (
    <StyledImageWrapper>
      <StyledFileField
        id="contained-button-file"
        type="file"
        onChange={(e) => handleUploadImage(e)}
      />
      <StyledInputLabel htmlFor="contained-button-file">
        <Fab component="span">
          <img src={ImageUploadIcon} alt="Business logo" />
        </Fab>
      </StyledInputLabel>
    </StyledImageWrapper>
  )
}