import React, { useCallback, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Link, Radio, RadioGroup, Typography } from '@mui/material';
import LightMode from 'components/TheamColorSettings/LightMode';
import DarkMode from 'components/TheamColorSettings/DarkMode';
import ShareIcon from 'assets/svg/share.svg';

import { SettingsBoxRadio, SettingsBoxRadioDark, StyledFooterSettings, StyledFormControl } from './styles';
import ShareModal from 'components/Modals/ShareModal';
import { useAppSelector } from 'hooks/useReduxHook';
import { useGetQRQuery, useGetSettingsQuery, useUpdateSettingsMutation } from 'api';
import { businessSelectors } from 'features/business';
import LoadingButton from 'components/UI/LoadingButton';
import Preloader from 'components/UI/Preloader/Preloader';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';

const WebsiteSettings = () => {
  function getRadioButtonName(selectedMode: 'light' | 'dark' | '', currentMode: 'light' | 'dark') {
    return selectedMode === currentMode ? 'radio-button-on' : 'radio-button-off';
  }
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [currentMode, setCurrentMode] = useState<'light' | 'dark' | ''>('');
  const { data: settings, isFetching: isGetLoading } = useGetSettingsQuery(undefined);
  const [updateSettings, { isLoading: isUpdateLoading }] = useUpdateSettingsMutation();
  const business = useAppSelector(businessSelectors.business);
  const { data: qrData, isFetching: isLoading } = useGetQRQuery(business?.id ?? '', { skip: !business?.id });

  const downloadImage = () => {
    saveAs(qrData?.qr, 'QR.jpg'); // Put your image url here.
  };
  // useEffect(() => {
  //   if (settings?.websiteMode) {
  //     setCurrentMode(settings?.websiteMode);
  //   }
  // }, [settings]);

  const handleUpdateSettings = useCallback(async () => {
    if (currentMode && settings) {
      await updateSettings({
        websiteMode: currentMode,
        menuCurrencyId: settings.menuCurrencyId,
      }).unwrap();
      setCurrentMode('');
    }
  }, [currentMode, settings]);

  return (
    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
      {isGetLoading ? (
        <Preloader overlay sx={{ margin: 0 }} />
      ) : (
        <>
          <ShareModal isOpen={isOpen} setIsOpen={setIsOpen} />
          <Grid container sx={{ p: 3, height: '100%' }}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box sx={{ mb: 4, flexDirection: 'row' }}>
                <Box>
                  <Typography>
                    You can choose your preferred layout mode for your product consumers experience.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <StyledFormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={settings?.websiteMode === 'dark' ? 'Dark mode' : 'Light mode'}
                    name="radio-buttons-group"
                  >
                    <SettingsBoxRadio>
                      <LightMode />
                      <FormControlLabel
                        onClick={() => setCurrentMode('light')}
                        value="Light mode"
                        name="light"
                        control={<Radio color="default" size="small" name={getRadioButtonName(currentMode, 'light')} />}
                        label="Light mode"
                      />
                    </SettingsBoxRadio>

                    <SettingsBoxRadioDark>
                      <DarkMode />
                      <FormControlLabel
                        onClick={() => setCurrentMode('dark')}
                        value="Dark mode"
                        name="dark"
                        control={<Radio color="default" size="small" name={getRadioButtonName(currentMode, 'dark')} />}
                        label="Dark mode"
                      />
                    </SettingsBoxRadioDark>
                  </RadioGroup>
                </StyledFormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <Typography>QR information</Typography>
                </Box>
                <Box
                  sx={{
                    minHeight: '211px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {isLoading || !qrData ? (
                    <Preloader overlay sx={{ margin: 0 }} />
                  ) : (
                    <>
                      <Box>
                        <Box sx={{ mt: 3 }}>
                          <img src={qrData?.qr} alt="QR Code" width={180} />
                        </Box>
                        <Button onClick={downloadImage}>
                          <DownloadIcon sx={{ mr: 1 }} /> Download QR!
                        </Button>
                      </Box>
                      <Link
                        href={qrData?.link}
                        sx={{
                          mt: 3,
                          overflow: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '0px',
                          },
                        }}
                        target="_blank"
                      >
                        {qrData?.link}
                      </Link>
                    </>
                  )}
                </Box>

                <Box sx={{ mt: 5 }}>
                  <Button type={'submit'} variant={'contained'} onClick={() => setIsOpen(true)}>
                    <img src={ShareIcon} alt="QR Code" style={{ marginRight: '10px' }} />
                    Share
                  </Button>
                </Box>
                <StyledFooterSettings sx={{ mt: 3, justifyContent: 'flex-start' }}>
                  <LoadingButton
                    isLoading={isUpdateLoading}
                    sx={{ width: '375px' }}
                    type={'submit'}
                    variant={'contained'}
                    onClick={handleUpdateSettings}
                    disabled={!currentMode}
                  >
                    Save
                  </LoadingButton>
                </StyledFooterSettings>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default WebsiteSettings;
