import { Accordion, Box, ListItemText, AccordionSummary, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAccardion = styled(Accordion)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  margin: theme.spacing(0, 0, 2, 0),
  // borderRadius: '4px',
  boxShadow: 'none',
  position: 'relative',
  ':before': {
    display: 'none',
  },
})) as typeof Accordion;

export const StyledAccordionSummary = styled(AccordionSummary)(({}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: 0,
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
  },
})) as unknown as typeof AccordionSummary;

export const TitleBox = styled(Box)(({}) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
})) as typeof Box;

export const AccardionItemTitle = styled(Typography)(({}) => ({
  fontSize: '20px',
  fontWeight: 700,
  color: 'black',
})) as typeof ListItemText;
