import { isIntCurrency } from './currencies';

/**
 * Util for converting integeres or unfinished float input to correct float numbers
 * [00.00]
 */
export function formatPriceInput(userInput: string | undefined, currencyCode: string) {
  if (!userInput) return '';

  if (isIntCurrency(currencyCode)) return parseInt(userInput).toString();

  if (!userInput.includes('.')) {
    return `${userInput}.00`;
  }

  const priceParts = userInput.split('.');

  if (priceParts[1].length === 1) {
    return `${userInput}0`;
  }

  if (priceParts[1].length === 0) {
    return `${userInput}00`;
  }

  if (priceParts[1].length > 2) {
    return `${priceParts[0]}.${priceParts[1].slice(0, 2)}`;
  }

  return userInput;
}

export function formatPriceIntOrFloat(price: number, currencyCode: string) {
  return isIntCurrency(currencyCode) ? parseInt(price?.toString()).toString() : Number(price)?.toFixed(2);
}
