// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { arrayMove } from 'react-sortable-hoc';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, MenuItem, Typography } from '@mui/material';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { defaultPriceValue, defaultValues, schemaItem } from './itemSchema';
import { STATUS } from 'constants/global';
import { StyledFooterSettings } from 'pages/WebsiteSettings/styles';
import CustomMultiselect from 'components/FormFields/CustomMultiselect';
import {
  useCreateItemMutation,
  useGetCategoryListQuery,
  useGetClassificationsQuery,
  useGetMenuListQuery,
  useLazyUploadPictureQuery,
} from 'api';
import ItemImage from 'components/ItemImage';
import { getBoolStatusValue } from 'utils/uploadImage';
import { useAppSelector } from 'hooks/useReduxHook';
import { businessSelectors } from 'features/business';
import LoadingButton from 'components/UI/LoadingButton';
import SortableList from './SoratbleItem';
import configSlice, { configSelectors } from 'features/config';
import { useDispatch } from 'react-redux';
import { categoriesSelectors } from 'features/categories';

const CreateItem = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const haveFormChange = useAppSelector(configSelectors.haveFormChange);
  const { data: classifications } = useGetClassificationsQuery(undefined);
  const businessId = useAppSelector(businessSelectors.business)?.id ?? '';
  const { data: menuList } = useGetMenuListQuery(businessId, { skip: !businessId });
  const [createItem] = useCreateItemMutation();
  const [uploadImage] = useLazyUploadPictureQuery();
  const [images, setImages] = useState<any>([]);
  const [value, setValue] = useState<any>([]);
  const [defaultImage, setDefaultImage] = useState(0);
  const [isLoadingItem, setLoadingItems] = useState(false);
  const currentMenuId = useAppSelector(categoriesSelectors.selectedMenuId);
  const itemForm = useForm({
    mode: 'all',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaItem),
  });

  const { control, watch, reset } = itemForm;
  const { fields, append } = useFieldArray({
    control,
    name: 'price',
  });
  const menuId = watch('menuId');
  const { data: categoryList } = useGetCategoryListQuery(menuId, { skip: !menuId });

  useEffect(() => {
    if (menuList && menuList?.length === 1) {
      itemForm.reset({
        ...itemForm.getValues(),
        menuId: currentMenuId ?? '',
      });
    }
  }, [menuList, currentMenuId]);

  const handleAddPrice = useCallback(() => {
    append(defaultPriceValue);
  }, [append]);

  const handleRemovePrice = useCallback(
    (id: number) => {
      fields.splice(id, 1);
      reset({ ...itemForm.getValues(), price: fields });
    },
    [fields, reset, itemForm],
  );

  useEffect(() => {
    if (!haveFormChange || images?.length) {
      dispatch(configSlice.actions.formChangeWatcher(itemForm.formState.isDirty || images?.length));
    }
  }, [itemForm.formState.isDirty, images]);

  const handleSubmit = useCallback(
    async (values: any) => {
      setLoadingItems(true);
      const promises: Array<Promise<ImageDTO> | ImageDTO | any> = [];
      for (let i = 0; i < images?.length; i++) {
        const formData = new FormData();
        formData.append('file', images[i]);
        const result = await uploadImage(formData).unwrap();
        promises.push(result);
      }
      const uploadedPictures = await Promise.all(promises).then((images) => {
        return images.map((image, index) => ({
          ...image,
          default: index === defaultImage,
        }));
      });

      setTimeout(() => {
        createItem({
          name: values.name,
          description: values.description,
          pictures: uploadedPictures,
          menuId: values.menuId,
          categoryId: values.category,
          price: values.price,
          status: getBoolStatusValue(values.status),
          classification: values.classification,
          calories: values.calories ? Number(values.calories) : undefined,
        })
          .then(() => {
            navigator(-1);
            setLoadingItems(false);
            dispatch(configSlice.actions.formChangeWatcher(false));
          })
          .catch(() => {
            setLoadingItems(false);
          });
      }, 1000);
    },
    [images, defaultImage],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortData = arrayMove(itemForm.getValues()?.price || fields, oldIndex, newIndex);
    reset({ ...itemForm.getValues(), price: sortData });
  };

  return (
    <FormProvider {...itemForm}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 3 }}>
        {/* <Grid container spacing={2} sx={{ px: 3 }}>
          <Grid item sm={12} xs={12} md={12} lg={8}> */}
        <Box component="form" maxWidth={750} mt={1}>
          {/* <Grid container columnSpacing={1} rowSpacing={0.875} flexDirection="column" mb={3}>
            <Grid item> */}
          <Typography mt={3} mb={2}>
            <Box component="strong">Item photo</Box> (Add up to 3 images)
          </Typography>
          <ItemImage
            setDefaultImage={setDefaultImage}
            setImages={setImages}
            images={images}
            defaultImage={defaultImage}
            itemForm={itemForm}
            value={value}
            setValue={setValue}
          />
          {/* </Grid>
            <Grid item> */}
          <CustomTextInput size="small" name="name" label="Name" />
          {/* </Grid>
            <Grid item> */}
          <CustomTextInput size="small" name="description" label="Description" multiline={true} rows={3} />
          {/* </Grid>
            <Grid item> */}
          <CustomTextInput size="small" name="status" label="Status" select={true}>
            {STATUS.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </CustomTextInput>
          {/* </Grid>
            <Grid item> */}
          <CustomTextInput size="small" name="menuId" label="Choose menu" select={true}>
            {menuList?.map((menu) => {
              return (
                <MenuItem key={menu.id} value={menu.id}>
                  {menu.name}
                </MenuItem>
              );
            })}
          </CustomTextInput>
          {/* </Grid>
            <Grid item> */}
          <CustomTextInput
            size="small"
            name="category"
            label={`${menuId ? 'Choose category' : 'No available options yet'}`}
            select={Boolean(menuId)}
            disabled={Boolean(!menuId)}
          >
            {categoryList?.map((category: any) => {
              return (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              );
            })}
          </CustomTextInput>
          {/* </Grid>
            <Grid item> */}
          <CustomTextInput
            size="small"
            inputProps={{
              type: 'number',
            }}
            name="calories"
            label="Calories"
          />
          {/* </Grid>
            <Grid item> */}
          <CustomMultiselect
            name="classification"
            label="Classification"
            options={classifications || []}
            multiple
            defaultValues={classifications?.[0] || []}
          />
          {/* </Grid>
          </Grid> */}
          <Box sx={{ mt: 2 }}>
            <SortableList
              itemForm={itemForm}
              handleRemovePrice={handleRemovePrice}
              useDragHandle
              items={fields}
              onSortEnd={onSortEnd}
            />
          </Box>
          {/* <Grid container columnSpacing={2} mb={4}>
            <Grid item> */}
          <Typography
            sx={{
              cursor: 'pointer',
            }}
            fontSize={20}
            fontWeight={500}
            color="#007DFF"
            lineHeight="26px"
            onClick={handleAddPrice}
          >
            + Add price
          </Typography>
          {/* </Grid>
          </Grid> */}
          <StyledFooterSettings sx={{ mt: 2 }}>
            <LoadingButton
              isLoading={isLoadingItem}
              sx={{ width: '375px' }}
              type="submit"
              variant="contained"
              onClick={itemForm.handleSubmit(handleSubmit)}
            >
              Save
            </LoadingButton>
          </StyledFooterSettings>
        </Box>
        {/* </Grid>
        </Grid> */}
      </Box>
    </FormProvider>
  );
};

export default CreateItem;
