import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/auth';
import businessReducer from 'features/business';
import overviewReducer from 'features/overview';
import categoriesReducer from 'features/categories';
import navigationReducer from 'features/navigation';
import menuDataReucer from 'features/menuData';
import errorReducer from 'features/error';
import { api } from 'api';
import { rtkQueryErrorLogger } from 'features/errorLogger';
import configSlice from 'features/config';

const middlewares = [api.middleware, rtkQueryErrorLogger];

// if (__DEV__) {
//   const createDebugger = require("redux-flipper").default;
//   middlewares.push(createDebugger());
// }

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer.reducer,
    business: businessReducer.reducer,
    overview: overviewReducer.reducer,
    categories: categoriesReducer.reducer,
    navigation: navigationReducer.reducer,
    menuData: menuDataReucer.reducer,
    config: configSlice.reducer,
    error: errorReducer.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    /** @TODO refactor it only to uploadImage action */
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export default store;
