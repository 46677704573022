import { Box, Button, Link, Typography, styled } from '@mui/material';

export const BackgroundImage = styled('img')({
  width: '100%',
  height: '100%',
});

export const AbsoluteBox = styled(Box)({
  position: 'absolute',
});

export const SignUpBox = styled(Box)(({ theme }) => ({
  maxWidth: '502px',
  //   height: '592px',
  borderRadius: '32px',
  background: '#FFFFFF',
  padding: `${theme.spacing(6)} ${theme.spacing(10)}`,
  boxSizing: 'border-box',
  margin: theme.spacing(2),
}));

export const Title = styled(Typography)({
  fontSize: '32px',
  color: 'black',
  textAlign: 'center',
  fontWeight: '600',
});

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  color: 'black',
  textAlign: 'center',
  fontWeight: '500',
  marginTop: theme.spacing(3),
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: 'black',
  textDecorationColor: 'black',
  textAlign: 'center',
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '14px',
  textAlign: 'center',
  marginTop: theme.spacing(5),
}));

export const AppleButton = styled(Button)(({ theme }) => ({
  background: 'black',
  position: 'relative',
  color: 'white',
  width: '100%',
  height: '50px',
  marginTop: theme.spacing(13),
  textTransform: 'none',
  '&:hover': {
    background: '#424242',
  },
}));

export const GoogleButton = styled(Button)(({ theme }) => ({
  background: '#ebebeb',
  position: 'relative',
  color: 'black',
  width: '100%',
  height: '50px',
  textTransform: 'none',
  marginTop: theme.spacing(1.5),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: '#ebebeb',
  color: 'black',
  width: '100%',
  height: '50px',
  textTransform: 'none',
  marginTop: theme.spacing(1.5),
}));

export const AuthTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  marginTop: theme.spacing(3),
}));

export const AuthBox = styled(Box)(({ theme }) => ({
  width: '502px',
  maxWidth: '502px',
  height: '592px',
  borderRadius: '32px',
  background: '#FFFFFF',
  padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2),
  '@media (max-width: 600px)': {
    width: '95%',
    margin: `0 auto`,
  },
}));

export const AuthSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  marginTop: theme.spacing(2.5),
}));

export const FullSizeBox = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
}));

export const StyledFormBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const StyledForm = styled('form')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const StyledAuthLogoImg = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1),
  width: '24px',
  position: 'absolute',
  left: theme.spacing(2),
}));
