import validImg from 'assets/img/valid/valid.png';
import inValidImg from 'assets/img/inValid/inValid.png';
import { Box, Typography } from '@mui/material';

type FieldFeedbackProps = {
  when: (val: string) => boolean | '';
  message: string;
  value: string;
};

const FieldFeedback = ({ when, message, value }: FieldFeedbackProps) => {
  const valid = when(value) && true;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
      <img src={valid ? validImg : inValidImg} alt="Feedback" />
      <Typography sx={{ ml: 1 }}>{message}</Typography>
    </Box>
  );
};

export default FieldFeedback;
