import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './useReduxHook';
import { useLazyGetBusinessQuery } from 'api';
import authSlice, { authSelectors } from 'features/auth';
import businessSlice from 'features/business';
import { useNavigate } from 'react-router-dom';

/**
 * Get initial data from AsyncStorage or server
 */
export function useInitData() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(authSelectors.user);
  const [getBusiness] = useLazyGetBusinessQuery();
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    /**
     * Try to login from AsyncStorage
     */
    const savedUser = localStorage.getItem('user');
    // AsyncStorage.getItem('user').then((savedUser) => {
    if (savedUser) {
      const currentUser: UserDTO = JSON.parse(savedUser);

      dispatch(authSlice.actions.loginFromAsyncStorage(currentUser));
    } else {
      dispatch(businessSlice.actions.setBusinessCheck(true));
    }

    dispatch(authSlice.actions.setUserCheck(true));
    // });
  }, []);

  useEffect(() => {
    if (user) {
      getBusiness(null)
        .unwrap()
        .catch((err) => {
          // Business not created yet
          if (err.status === 409) {
            navigate('/business-profile');
          }

          if (err.status === 401) {
            // user token has expired -> clean user data from AsyncStorage
            dispatch(authSlice.actions.logout());
          }
        });
    } else {
      // invalidate business check on logout
      dispatch(businessSlice.actions.clearCurrentBusiness());
    }
  }, [user]);

  return { user };
}
