import React from 'react';
import { Box, Divider, Grid, useTheme } from '@mui/material';
import qrImgBackground from 'assets/img/qrImg.png';
import OrdersLogo from 'assets/svg/ordersco.svg';
import PoweredBy from 'assets/svg/poweredBy.svg';
import AppleLogo from 'assets/img/apple/apple@2x.png';
import GoogleLogo from 'assets/img/google/google@2x.png';

import {
  // AppleButton,
  BackgroundImage,
  GoogleButton,
  SignUpBox,
  StyledLink,
  StyledText,
  SubTitle,
  Title,
  StyledButton,
  AbsoluteBox,
  StyledAuthLogoImg,
  AppleButton,
} from './styles';
import { CenteredBox } from 'styles';
import { useNavigate } from 'react-router-dom';
import { LoginSocialApple, LoginSocialGoogle } from 'reactjs-social-login';
import { useLazyAppleRedirectQuery, useLazyGoogleRedirectQuery } from 'api';
import { useDispatch } from 'react-redux';
import { useMatchTablet } from 'hooks/useMatchMobile';

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMatchTablet } = useMatchTablet();
  const [sendGoogleToken, { isLoading: googleLoading }] = useLazyGoogleRedirectQuery();
  const [sendAppleToken, { isLoading: appleLoading }] = useLazyAppleRedirectQuery();

  const theme = useTheme();

  const handleSignup = () => {
    navigate('/auth/email');
  };

  const googleSignResolve = async ({ provider, data }) => {
    console.log('data: ', data);
    const idToken = data.credential;
    await sendGoogleToken(idToken);
  };

  const appleSignResolve = async ({ provider, data }) => {
    alert(JSON.stringify(data));
    // await sendAppleToken(appleAuthRequestResponse.identityToken);
  };

  googleSignResolve;

  return (
    <Grid container>
      {!isMatchTablet && (
        <Grid item md={12} lg={5} sm={12} xs={12}>
          <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <AbsoluteBox>
              <img src={OrdersLogo} alt="Orders web app" />
            </AbsoluteBox>

            <BackgroundImage src={qrImgBackground} />
            <AbsoluteBox sx={{ left: '160px', bottom: theme.spacing(3) }}>
              <img src={PoweredBy} alt="Orders web app" />
            </AbsoluteBox>
          </Box>
        </Grid>
      )}
      <Grid item md={12} lg={7} sm={12} xs={12} sx={{ height: isMatchTablet ? '100vh' : 'auto' }}>
        <CenteredBox>
          <SignUpBox sx={{ paddingX: isMatchTablet ? 4 : 10 }}>
            <Title variant="h1">Let’s Get Started</Title>
            <SubTitle>Sign in to easily create and manage your restaurant menus.</SubTitle>

            <LoginSocialApple
              client_id={'com.qrmenumaker'}
              scope={'name email'}
              redirect_uri={'https://partners.qrmenu.orders.co'}
              onLoginStart={() => {}}
              onResolve={appleSignResolve}
              onReject={(err) => {
                alert(JSON.stringify(err));
                console.log(err);
              }}
            >
              <AppleButton>
                <StyledAuthLogoImg src={AppleLogo} alt={'Apple'} />
                Continue with Apple
              </AppleButton>
            </LoginSocialApple>
            <LoginSocialGoogle
              client_id={'1061433917885-e3hbo77h08870b69rhcng1vulkf2qab6.apps.googleusercontent.com'}
              onLoginStart={() => {}}
              // redirect_uri={'https://partners.qrmenu.orders.co'}
              redirect_uri={'http://localhost:3005'}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={googleSignResolve}
              onReject={async (err) => {
                // ".eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDYxNDMzOTE3ODg1LWUzaGJvNzdoMDg4NzBiNjlyaGNuZzF2dWxrZjJxYWI2LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA2MTQzMzkxNzg4NS1lM2hibzc3aDA4ODcwYjY5cmhjbmcxdnVsa2YycWFiNi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwODU5MDczNjAwNzM2NTg1NTE4OCIsImhkIjoib3JkZXJzLmNvIiwiZW1haWwiOiJkZXJlbmlrLm1pbmFzeWFuQG9yZGVycy5jbyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYmYiOjE2OTE1NzgyODMsIm5hbWUiOiJEZXJlbmlrIE1pbmFzeWFuIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FBY0hUdGRIWFY5X0VjVkotN0hWWFRPWkFXbWYyUTBBU0FKZzk4TW5rRkFhOEQwNzN3PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkRlcmVuaWsiLCJmYW1pbHlfbmFtZSI6Ik1pbmFzeWFuIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2OTE1Nzg1ODMsImV4cCI6MTY5MTU4MjE4MywianRpIjoiMWJkNTM2N2M2MTM4M2ZkMmY5NWRmYWE0ZTJiNWFkY2YwYjBlMzdmYSJ9.HTBm691dtFRuBP9bEk9s34sLPfpWoKG0r58nIe0uCe2ZZGq_wHKUrBiBzZsho4zMSGTGz5da7QNHN4gOYhZvxZTNWkxcRxPnTk1SyUUHCLKqby3tfEKYmfvq2ImjmV8wH1ill0Dy6gjS8FcYeBm9MdMyWALrUdi4ck3_ZvRcF2o8BFWttys1ahcCOSwLPVyOm7UYTI4E-w-MDEvDP_ZnFAGkxnN7sm_OHCNoFsieTvKawwxAVOA5cuyUn2httw260O7Fr_yerSPNzqt2e3Njk9yLTUvvetwGE-XjOhUwzw2r-ax-P3uxn-arkpyZoeGnvEc87cn-Cmv2OICUhV3h0g"

                console.log(err);
              }}
              typeResponse={'idToken'}
            >
              <GoogleButton>
                <StyledAuthLogoImg src={GoogleLogo} alt={'Google'} />
                Continue with Google
              </GoogleButton>
            </LoginSocialGoogle>

            <Divider sx={{ my: 2 }}>Or</Divider>
            <StyledButton onClick={handleSignup}>Continue with Email</StyledButton>
            <StyledText>
              By creating or logging in to an account you agree to our{' '}
              <StyledLink href="https://orders.co/terms-of-use/ ">Terms & Conditions</StyledLink> and{' '}
              <StyledLink href=" https://orders.co/privacy-policy/  ">Privacy Statement</StyledLink>.
            </StyledText>
          </SignUpBox>
        </CenteredBox>
      </Grid>
    </Grid>
  );
};
export default Auth;
