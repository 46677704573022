import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks/useReduxHook';
import { businessSelectors } from 'features/business';
import { useInitData } from 'hooks/useInitData';
import { useCurrency } from 'hooks/useCurrency';
import Main from 'pages/main';
import Auth from 'pages/auth';
import Preloader from 'components/UI/Preloader/Preloader';
import MobileDownloadBanner from 'components/MobileDownloadbanner/index';
import { CookiesProvider } from 'react-cookie';
import { useLocation } from 'react-router-dom';

function App() {
  const { user } = useInitData();
  const userFromStorage = localStorage.getItem('user');
  useCurrency();
  const localtion = useLocation();

  const businessProfile = useAppSelector(businessSelectors.business);
  const isBusinessChecked = useAppSelector(businessSelectors.isBusinessChecked);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [localtion]);

  if (!isBusinessChecked) {
    // We haven't finished checking for the existing business yet
    return <Preloader overlay sx={{ margin: 0 }} />;
  }

  if (businessProfile || user || userFromStorage) {
    return (
      <CookiesProvider>
        <MobileDownloadBanner />
        <Main />
      </CookiesProvider>
    );
  } else {
    return (
      <CookiesProvider>
        <MobileDownloadBanner />
        <Auth />
      </CookiesProvider>
    );
  }
}

export default App;
