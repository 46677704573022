import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CurrencyInputField from 'react-currency-input-field';
import { TextField } from '@mui/material';
import { get } from 'lodash';
import { CustomNumberInputI } from './types';
import { onWheelPreventChangeNumberField } from 'utils/itmes';

const CustomNumberInput = ({
  name,
  id,
  sx = {
    width: '100%',
    pb: 2,
  },
  children,
  label = '',
  inputProps,
  hidden,
  onChange,
  disabled,
  InputProps,
  size = 'medium',
  labelProps = {},
  decimalsLimit = 2,
  ...params
}: CustomNumberInputI) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const parsedError = get(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      {...params}
      render={({ field }) => {
        return (
          <>
            <TextField
              sx={sx}
              hidden={hidden}
              error={!!parsedError || params.error || false}
              helperText={parsedError ? parsedError.message : params.helperText || ''}
              size={size}
              disabled={disabled}
              InputLabelProps={labelProps}
              onWheel={onWheelPreventChangeNumberField}
              label={label}
              value={!decimalsLimit ? field.value : Number(field.value).toFixed(2)}
              InputProps={{
                ...InputProps,
                inputComponent: CurrencyInputField,
                inputProps: {
                  decimalsLimit,
                  disableGroupSeparators: true,
                  onValueChange: (value) => {
                    return field.onChange(value || '');
                  },
                },
              }}
            />
          </>
        );
      }}
    />
  );
};

export default CustomNumberInput;
