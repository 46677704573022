import React, {useEffect, useState} from 'react';
import DragImage from 'assets/img/drag.png';
import { StyledAccardion, AccardionItemTitle, StyledAccordionSummary, TitleBox } from './styles';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useDeleteCategoryMutation } from 'api/index';
import { Icon, Popover, IconButton, Box, Typography, AccordionDetails } from '@mui/material';
import PopoverContent from 'components/UI/PopoverContent/PopoverContent';
import ConfirmableModal from 'components/Modals/ConfirmableModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ItemsList from '../Items/ItemList';

interface ISortableDraggableMenuItemProps {
  name: string;
  id: string;
  list: any;
  menuId: string;
  status: boolean;
}
const DragHandle = SortableHandle(() => {
  return <img src={DragImage} alt={'drag'} style={{ width: '24px', height: '24px', cursor: 'grab' }} />;
});

const SortableDraggbleListItem = SortableElement<ISortableDraggableMenuItemProps>(
  ({ name, id, list, menuId, status }: ISortableDraggableMenuItemProps) => {
    const navigate = useNavigate();
    const [itemsList, setItemsList] = useState([]);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [deleteCategory] = useDeleteCategoryMutation();

    useEffect(() => {
      if(list && list?.length) {
        setItemsList(list);
      }
    },[list])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const handleCloseConfirmModal = () => setOpenConfirmModal(false);

    const open = Boolean(anchorEl);
    const ids = open ? 'simple-popover' : undefined;


    const handleEdit = () => {
      navigate(`/categories/edit?id=${id}`);
    };

    const handleDelete = () => {
      handleClose();
      setOpenConfirmModal(true);
    };

    const handleConfirm = async () => {
      await deleteCategory(id).unwrap();
      // setCurrentCatId('');
      setOpenConfirmModal(false);
      return;
    };
    return (
      <StyledAccardion defaultExpanded={true} >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <TitleBox>
            <Icon sx={{ color: '#9E9EAD' }} component={DragHandle} />
            <AccardionItemTitle>{name}</AccardionItemTitle>
          </TitleBox>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ color: '#3C3C4399', fontSize: '16px' }}>{`Items: ${itemsList?.length}`}</Typography>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {itemsList?.length ? (
            <ItemsList
              categoryId={id}
              menuId={menuId}
              products={itemsList}
              setItemsList={setItemsList}
            />
          ) : (
            <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
              No items created for this category yet
            </Typography>
          )}
        </AccordionDetails>

        <Popover
          id={ids}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PopoverContent
            editAction={'Edit'}
            deleteAction={'Delete'}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </Popover>
        <ConfirmableModal
          open={openConfirmModal}
          onClose={handleCloseConfirmModal}
          onConfirm={handleConfirm}
          title={'Delete'}
          message={'Are you sure you want to delete this item?'}
        />
      </StyledAccardion>
    );
  },
);

export default SortableDraggbleListItem;
