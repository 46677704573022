import { Grid } from '@mui/material';
import Preloader from 'components/UI/Preloader/Preloader';
import { useAppSelector } from 'hooks/useReduxHook';
import { categoriesSelectors } from 'features/categories';
import SortableCategoryList from './SortableCategoryList';
import { useMatchTablet } from 'hooks/useMatchMobile';

const CategoriesList = ({
  categories,
  onSortEnd,
  isSortingLoading,
}: {
  categories: any;
  onSortEnd: any;
  isSortingLoading: boolean;
}) => {
  const currentMenuId = useAppSelector(categoriesSelectors.selectedMenuId);
  const { isMatchTablet } = useMatchTablet();

  return (
    <Grid item xs={12}>
      {isSortingLoading && <Preloader overlay={true} sx={{ margin: isMatchTablet ? 0 : '100px' }} />}
      <SortableCategoryList
        items={categories}
        onSortEnd={onSortEnd}
        useDragHandle
        currentMenuId={currentMenuId}
        useWindowAsScrollContainer={true}
      />
    </Grid>
  );
};

export default CategoriesList;
