import * as yup from 'yup';
import { REQUIRED_MSG } from 'constants/global';

const pricesSchema = yup.object().shape({
  count: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(0, 'Price should be min 0')
    .required(REQUIRED_MSG),
  desciprtion: yup.string(),
});

const classSchema = yup.string().required(REQUIRED_MSG);

const imageSchema = yup.object().shape({
  id: yup.string(),
  fileId: yup.string(),
});

export const schemaItem = yup.object().shape({
  name: yup.string().required(REQUIRED_MSG),
  description: yup.string(),
  menuId: yup.string().required(REQUIRED_MSG),
  category: yup.string().required(REQUIRED_MSG),
  // calories: yup.number().transform((value) => (Number.isNaN(value) ? null : value)),
  // .min(0, 'Calories should be min 0')
  // .required(REQUIRED_MSG),
  classification: yup.array().of(classSchema),
  pictures: yup.array().of(imageSchema),
  price: yup.array().of(pricesSchema),
});

export const defaultPriceValue = {
  count: '',
  desciprtion: '',
};

export const defaultPicturesValue = {
  default: false,
  fileId: '',
  url: '',
};

export const defaultValues = {
  name: '',
  description: '',
  status: 'Active',
  menuId: '',
  category: '',
  calories: 0,
  classification: [],
  pictures: [defaultPicturesValue],
  price: [defaultPriceValue],
};
