import { useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AddImageIcon from 'assets/svg/addImageIcon.svg';
import PopoverImageIcon from 'assets/svg/popoverImageIcon.svg';
import DefaultImageFlagIcon from 'assets/svg/defaultImageFlagIcon.svg';
import PopoverProduct from 'components/ItemImage/PopoverImage';

const ItemImage = (props: any) => {
  const MAX_IMAGE = 3;
  const { setImages, setDefaultImage, defaultImage, itemForm, images, value, setValue, setImageTouched = () => {} } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionId, setActionId] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const inputRef: any = useRef();
  const handleUploadImage = ({ target: { files } }: any) => {
    const image = files[0];
    const formData: any = new FormData();
    formData.append('file', image);
    if (files[0]) {
      if (setImageTouched) {
        setImageTouched(true)
      }
      const imageData = {
        fileId: image ? uuidv4() : '',
        url: image ? URL.createObjectURL(image) : '',
      };
      if(value) {
        setValue([...value, imageData]);
        setImages([...images, image]);
      }else {
        setImages([image]);
        setValue([imageData]);
      }
    }
    inputRef.current.value = '';
  };

  const handleOpen = (event: any, id: number) => {
    setActionId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    const picturesCopy = [...value];
    const picturesCopy2 = [...images];
    picturesCopy.splice(actionId, 1);
    picturesCopy2.splice(actionId, 1);
    setValue(picturesCopy);
    setImages(picturesCopy2);
    if (setImageTouched) {
      setImageTouched(true)
    }
    // If we delete default image
    if (actionId === defaultImage) {
      setDefaultImage(0);
    }
    setAnchorEl(null);
  };

  const handleDefault = () => {
    const defaultIm = itemForm.getValues()?.pictures?.find((_, index) => index === actionId) || null;
    if (defaultIm) {
      defaultIm.default = true;
    }
    if(itemForm.getValues()?.pictures) {
      itemForm.setValue('pictures', [...itemForm.getValues()?.pictures], { shouldDirty: true });
    }else {
      itemForm.setValue('pictures', [], { shouldDirty: true });
    }
    setDefaultImage(actionId);
    setAnchorEl(null);
  };

  return (
    <>
      <PopoverProduct
        _id={id}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        handleDefault={handleDefault}
      />
      <Box mb={2} display="flex">
        {value?.length < MAX_IMAGE || !value  ? (
          <Box sx={{ width: '104px', height: '104px', mr: 2 }}>
            <Box component="input" value={value?.url} hidden type="text" name="pictures" />
            <IconButton
              onClick={handleUploadImage}
              size="small"
              sx={{ width: '104px', height: '104px', borderRadius: '21px' }}
            >
              <Box component="img" src={AddImageIcon} alt="Add image" />
              <Box
                component="input"
                ref={inputRef}
                sx={{
                  opacity: 0,
                  width: 104,
                  height: 104,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  cursor: 'pointer',
                }}
                type="file"
                onChange={handleUploadImage}
                disabled={false}
                accept="image/png, image/jpeg"
              />
            </IconButton>
          </Box>
        ) : (
          <></>
        )}
        {value?.length ? (
          value?.map((item: any, index: number) => {
            return (
              <Box
                key={`${item.fileId}-${index}`}
                sx={{
                  width: '104px',
                  height: '104px',
                  objectFit: 'cover',
                  borderRadius: '21px',
                  mr: 2,
                  position: 'relative',
                }}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    width: '32px',
                    height: '32px',
                  }}
                  onClick={(e: any) => handleOpen(e, index)}
                >
                  <Box component="img" src={PopoverImageIcon} alt="Popover icon" />
                </IconButton>

                {item?.url ? (
                  <Box component="img" width={104} height={104} src={`${item?.url}`} sx={{ borderRadius: '21px' }} /> //?tr=w-104,h-104
                ) : (
                  <></>
                )}
                {defaultImage === index ? (
                  <Box
                    component="img"
                    src={DefaultImageFlagIcon}
                    alt="Default flag icon"
                    sx={{
                      position: 'absolute',
                      bottom: '-5px',
                      right: '-5px',
                    }}
                  />
                ) : (
                  <></>
                )}
              </Box>
            );
          })
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default ItemImage;
