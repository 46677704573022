export function unwrapResponse(response: { data: unknown }) {
  return response.data;
}

export function unwrapError(err: { error: unknown }) {
  return err.error;
}

export function defaultTransformResponse(baseQueryReturnValue: { data: unknown }, meta: unknown, arg: unknown) {
  return unwrapResponse(baseQueryReturnValue) as any;
}

export function getSortedResponse(baseQueryReturnValue: { data: unknown }, meta: unknown, arg: unknown) {
  const arr: CategoryWithItemsCountDTO[] = unwrapResponse(baseQueryReturnValue) as any;

  arr.sort((current, next) => {
    return current.sort > next.sort ? 1 : -1;
  });

  return arr;
}

export function getSortedItemsInCategory(baseQueryReturnValue: { data: unknown }, meta: unknown, arg: unknown) {
  const categoriesArray: CategoryWithItems[] = unwrapResponse(baseQueryReturnValue) as any;
  categoriesArray.sort((current, next) => {
    return current.sort > next.sort ? 1 : -1;
  });

  categoriesArray.forEach((category) => {
    category.products.sort((current, next) => {
      return current.sort > next.sort ? 1 : -1;
    });
  });

  return categoriesArray;
}

export function getSortedCurrenciesArray(baseQueryReturnValue: { data: unknown }, meta: unknown, arg: unknown) {
  const currenciesArray: CurrencyDTO[] = unwrapResponse(baseQueryReturnValue) as any;

  // Put USD to the first place
  // const usdCurrency = currenciesArray.find((currency) => currency.currencyCode === 'USD')!;
  // console.log(usdCurrency, 'usdCurrency')

  return [...currenciesArray];
}
