import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'features';

type NavigationState = {
  title: string;
  path: string;
  backIcon: boolean;
};

// Define the initial state using that type
const initialState: NavigationState = {
  title: '',
  path: '',
  backIcon: false,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation(state, action) {
      state.title = action.payload.title;
      state.path = action.payload.path;
      state.backIcon = action.payload.backIcon;
    },
  },
  // extraReducers: (builder) => {},
});

// Other code such as selectors can use the imported `RootState` type
export const categoriesSelectors = {
  setNavigation: (state: RootState) => state.navigation,
};

export default navigationSlice;
