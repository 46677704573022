import {
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  Select,
  Chip,
  Checkbox,
} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

export default function CustomMultiselect({ options, name, label, formProps, defaultValues = [], sx, ...params }: any) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        field.value = field.value ? field.value : [];
        return (
          <FormControl fullWidth {...params} size="small" sx={{ ...sx, background: '#fff' }}>
            <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
            <Select
              fullWidth
              labelId="demo-multiple-chip-label"
              multiple
              defaultValue={defaultValues}
              value={field.value?.map((item: any) => (typeof item === 'string' ? item : item?.id))}
              onChange={field.onChange}
              input={<OutlinedInput id="select-multiple-chip" label={label} />}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected?.map((value: any) => (
                      <Chip key={value} label={options.find((e: any) => e.id === value)?.name} />
                    ))}
                  </Box>
                );
              }}
            >
              {options.map((data: any) => {
                return (
                  <MenuItem key={data?.id} value={data.id}>
                    <Checkbox checked={field.value.indexOf(data.id) >= 0} />
                    <ListItemText primary={data.name} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }}
    />
  );
}
