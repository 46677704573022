import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Box, Grid, Icon, IconButton, InputAdornment, List, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { useAppSelector } from 'hooks/useReduxHook';
import { configSelectors } from 'features/config';
import CustomNumberInput from 'components/FormFields/CustomNumberInput';
import { useMatchTablet } from 'hooks/useMatchMobile';

const SortIcon = SortableHandle(() => {
  return (
    <IconButton>
      <MenuIcon />
    </IconButton>
  );
});

const SortableItem: any = SortableElement(({ items, itemForm, value, handleRemovePrice, propsIndex }) => {
  const priceName = `price[${propsIndex}]`;
  const currency = useAppSelector(configSelectors.selectedCurrency) ?? '';
  const { isMatchTablet } = useMatchTablet();
  return (
    <ListItem sx={{ p: 0 }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
          <Box pb={2} height="100%">
            <Icon component={SortIcon} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <CustomNumberInput
            InputProps={{
              startAdornment: <InputAdornment position="start">{currency?.symbol}</InputAdornment>,
            }}
            size="small"
            name={`${priceName}.count`}
            label="Price"
            decimalsLimit={currency?.currencyCode === 'AMD' ? 0 : 2}
          />
        </Grid>
        <Grid pl={0} item xs={isMatchTablet ? 5 : 6}>
          <CustomTextInput size="small" name={`${priceName}.desciprtion`} label="Label (Optional)" />
        </Grid>
        {items?.length > 1 ? (
          <Grid item xs={isMatchTablet ? 2 : 1}>
            <IconButton
              onClick={() => handleRemovePrice(propsIndex)}
              sx={{
                // width: '100%',
                // width: '30px',
                background: '#fff',
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                // padding: '7px',
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </ListItem>
  );
});

const SortableList = SortableContainer(({ items, itemForm, handleRemovePrice }) => {
  return (
    <List sx={{ p: 0 }}>
      {items?.map((value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            items={items}
            itemForm={itemForm}
            handleRemovePrice={handleRemovePrice}
            value={value}
            propsIndex={index}
            index={index}
          />
        );
      })}
    </List>
  );
});

export default SortableList;
