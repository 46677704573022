import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Box, CircularProgress } from '@mui/material';

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ isLoading, children, ...rest }) => {
  return (
    <Button disabled={isLoading} {...rest}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
        {isLoading && <CircularProgress sx={{ ml: 2 }} size={24} color="inherit" />}
      </Box>
    </Button>
  );
};
export default LoadingButton;
