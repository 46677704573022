import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CustomDialogWrapper } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useMatchMobile } from 'hooks/useMatchMobile';
interface ConfirmableModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  cancelButton?: string;
  confirmButton?: string;
  hideCloseIcon?: boolean;
}

const ConfirmableModal: React.FC<ConfirmableModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  cancelButton = 'Cancel',
  confirmButton = 'Remove',
  hideCloseIcon = false,
}) => {
  const { isMatchMobile } = useMatchMobile();
  return (
    <CustomDialogWrapper
      fullScreen={isMatchMobile}
      open={open}
      onClose={onClose}
      sx={{ '& .MuiPaper-root': { width: '502px' } }}
    >
      <DialogTitle sx={{ fontSize: '20px', fontWeight: 'bold' }}>
        {title}
        {hideCloseIcon && (
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 5, top: 5 }}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ ml: 1, fontSize: '16px', fontWeight: 500, color: '#000000' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid  #E9F0F6', py: 2 }}>
        <Button onClick={onClose} color="primary" sx={{ fontWeight: 500, color: 'black', mr: 1 }}>
          {cancelButton}
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary" sx={{ fontWeight: 500, color: 'white' }}>
          {confirmButton}
        </Button>
      </DialogActions>
    </CustomDialogWrapper>
  );
};

export default ConfirmableModal;
