import { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { PreloaderBlock, PreLoaderContainer } from 'components/UI/Preloader/styles';
import { useMatchTablet } from 'hooks/useMatchMobile';

export interface IPreLoader {
  overlay?: boolean;
  sx?: any;
}
const Preloader = ({ overlay, sx = {} }: IPreLoader) => {
  const { isMatchTablet } = useMatchTablet();
  // marginLeft: '129px',
  return (
    <PreLoaderContainer sx={{ ...sx }} className={`${overlay ? 'overlay' : ''}`}>
      <PreloaderBlock>
        <CircularProgress />
      </PreloaderBlock>
    </PreLoaderContainer>
  );
};

export default memo(Preloader);
