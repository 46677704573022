import { MiddlewareAPI, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import store from 'store';
import { setError } from './error';
// import { showMessage } from "utils/message";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 'FETCH_ERROR') {
      store.dispatch(
        setError({
          message:
            action?.payload?.data?.message ||
            action.payload.message ||
            'Your internet connection is lost, please check your internet connection',
          title: action.payload.data.title || '',
        }),
      );
    } else {
      if (action.payload.status !== 409) {
        store.dispatch(
          setError({
            message:
              action?.payload?.data?.errorMessage ||
              action?.payload?.data?.message ||
              action.payload.message ||
              'Oops! Something went wrong',
            title: action.payload.data.title || '',
          }),
        );
      }
    }
  }

  return next(action);
};
