import { useChangeItemSortingMutation } from 'api';
import SortableItemList from './SortableItemList';
import { arrayMove } from 'react-sortable-hoc';
import { Box } from '@mui/material';

const ItemsList = ({ products, categoryId, menuId, setItemsList }: { products: any; menuId: string; categoryId: string, setItemsList: any }) => {
  const [updateSorting, { isLoading }] = useChangeItemSortingMutation();

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex === newIndex) {
      return;
    }
    let sortedData = arrayMove(products, oldIndex, newIndex);
    setItemsList(sortedData);
    const newItemsOrder: any = sortedData.map((item: any, index) => ({
      id: item?.id,
      sort: index,
    }));
    updateSorting({ items: newItemsOrder, menuId, categoryId });
  };

  return (
    <Box>
      <SortableItemList
        isLoading={isLoading}
        list={products}
        onSortEnd={onSortEnd}
        useDragHandle
        useWindowAsScrollContainer={true}
      />
    </Box>
  );
};

export default ItemsList;
