import React, { useState } from 'react';
import {
  MenuPaper,
  MenuPaperTexts,
  MenuPaperContainer,
  MenuPaperListContent,
  MenuPaperListItemTitle,
  CategoryPaperListWrapper,
  MenuPaperListItemDescription,
  CategoryPaperListCountWrapper,
} from 'components/Menu/styles';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItem, Box, Popover, IconButton, Typography } from '@mui/material';
import PopoverContent from 'components/UI/PopoverContent/PopoverContent';
import ConfirmableModal from 'components/Modals/ConfirmableModal';
import { useDeleteCategoryMutation } from 'api';
import { menuDataSelectors } from 'features/menuData';
import { useAppSelector } from 'hooks/useReduxHook';
import { updateAllCategories } from 'features/menuData';
import { useDispatch } from 'react-redux';

interface ISortableDraggableMenuItemProps {
  name: string;
  description: string;
  id: string;
  countOfItems?: number;
  status: boolean;
}

const CategoryListItem = ({ name, description, id, countOfItems, status }: ISortableDraggableMenuItemProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const categoryList = useAppSelector(menuDataSelectors.allCategories);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => setAnchorEl(null);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);
  const [deleteCategoryQuery] = useDeleteCategoryMutation();
  const open = Boolean(anchorEl);
  const ids = open ? 'simple-popover' : undefined;

  const handleEdit = () => {
    navigate(`/categories/edit?id=${id}`);
  };

  const handleDelete = () => {
    handleClose();
    setOpenConfirmModal(true);
  };

  const handleConfirm = async () => {
    await deleteCategoryQuery(id).unwrap();
    const newList = categoryList.filter((item) => item.id !== id);
    dispatch(updateAllCategories(newList));
    setOpenConfirmModal(false);
  };

  return (
    <MenuPaper sx={{ cursor: 'pointer' }}>
      <MenuPaperContainer onClick={handleEdit}>
        <MenuPaperTexts>
          <MenuPaperListContent>
            <CategoryPaperListWrapper>
              <Box sx={{ opacity: !status ? 0.4 : 1 }}>
                <ListItem disablePadding>
                  <MenuPaperListItemTitle primary={name} />
                </ListItem>
                {description && (
                  <ListItem disablePadding>
                    <MenuPaperListItemDescription primary={description} />
                  </ListItem>
                )}
                {!status && (
                  <ListItem disablePadding  sx={{ fontSize: '14px', color: '#3C3C43' }}>
                    <MenuPaperListItemDescription primary={'Disabled'} />
                  </ListItem>
                )}
              </Box>
            </CategoryPaperListWrapper>
          </MenuPaperListContent>
        </MenuPaperTexts>
        <CategoryPaperListCountWrapper>
          {countOfItems && countOfItems >= 0 ? <Typography>Items: {countOfItems}</Typography> : ''}
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </CategoryPaperListCountWrapper>
      </MenuPaperContainer>
      <Popover
        id={ids}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContent
          editAction={'Edit'}
          deleteAction={'Delete'}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </Popover>
      <ConfirmableModal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirm}
        title={'Delete'}
        message={'Are you sure you want to delete this item?'}
      />
    </MenuPaper>
  );
};

export default CategoryListItem;
