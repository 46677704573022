import { createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { RootState } from 'features';

type ConfigState = {
  currencies: CurrencyDTO[];
  selectedCurrency: CurrencyDTO | null;
  haveFormChange: boolean;
};

// Define the initial state using that type
const initialState: ConfigState = {
  currencies: [],
  selectedCurrency: null,
  haveFormChange: false,
};

export const configSlice = createSlice({
  name: 'config',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedCurrency(state, action) {
      state.selectedCurrency = action.payload;
    },
    formChangeWatcher(state, action) {
      state.haveFormChange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getCurrencies.matchFulfilled, (state, { payload }) => {
      if (!payload || !payload.length) return;
      // Unsanitizied array may come from backend
      state.currencies = payload.filter((currency) => typeof currency === 'object' && 'id' in currency);
    });
    builder.addMatcher(api.endpoints.updateSettings.matchFulfilled, (state, { meta }) => {
      if (meta.arg.originalArgs.menuCurrencyId !== state.selectedCurrency?.id) {
        if (state.currencies.length > 0) {
          const newCurrency = state.currencies.find((currency) => currency.id === meta.arg.originalArgs.menuCurrencyId);
          if (newCurrency) {
            state.selectedCurrency = newCurrency;
          }
        }
      }
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const configSelectors = {
  currencies: (state: RootState | any) => state.config.currencies,
  selectedCurrency: (state: RootState | any) => state.config.selectedCurrency,
  haveFormChange: (state: RootState | any) => state.config.haveFormChange,
};

export default configSlice;
