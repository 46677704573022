export const endpoints = {
  /** Auth */
  checkEmail: () => '/auth/email',
  signUp: () => '/auth/sign-up',
  signIn: () => '/auth/sign-in',
  forgotPassword: () => `/auth/forgot-password`,
  forgotPasswordConfirm: () => `/auth/forgot-password/confirm`,
  resetPassword: () => `/auth/reset-password`,
  emailConfirm: () => 'auth/email/confirm',
  resendEmailConfirm: () => 'auth/email/resend',
  appleRedirect: () => '/auth/apple/redirect',
  googleRedirect: () => '/auth/google/redirect',
  profile: () => '/profile/me',

  /** Business */
  newBusiness: () => '/business',
  existingBusiness: () => '/business',
  businessChecks: () => '/business/checks',

  /**
   * Common
   */
  uploadPicture: () => '/picture/upload',
  deletePicture: () => '/picture/delete',

  /** Menu */
  newMenu: () => '/menu',
  existingMenu: (id: string) => `/menu/${id}`,
  menuListByBusinessId: (businessId: string) => `/menu/list/${businessId}`,
  menuSorting: () => '/menu/sorting',

  /** Category */
  newCategory: () => '/category',
  existingCategory: (id: string) => `/category/${id}`,
  categoryListByMenuId: (menuId: string) => `/category/list/${menuId}`,
  categoryListAll: (userId: string) => `/category/all/${userId}`,
  categorySorting: () => '/category/sorting',

  /** Item */
  newItem: () => '/item',
  existingItem: (id: string) => `/item/${id}`,
  itemSorting: () => '/item/sorting',
  saveItemImage: (id: string) => `item/${id}/picture`,
  deleteItemImage: (id: string, imgId: string) => `/item/${id}/picture/${imgId}`,
  classifications: () => `/configs/classifications`,

  /** Overview */
  categoriesWithItems: (menuId: string) => `/category/products/list/${menuId}`,
  getAllMenuWithCategoryItems: (menuId: string) => `/web/menu/${menuId}`,
  /** QR */
  qr: (businessId: string) => `profile/qr/${businessId}`,

  /** Profile */
  sendFeedback: () => '/profile/help',
  settings: () => '/configs',
  currencies: () => '/configs/currency',
};
