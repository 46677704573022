export function createUploadImageData(image: LocalImageDTO) {
  const formData: any = new FormData();

  formData.append('file', {
    uri: image?.uri,
    type: image?.mimeType,
    name: image?.fileName || `Image_${Date.now()}`,
  });

  return formData;
}

export function getBoolStatusValue(value: 'Active' | 'Disabled') {
  return value === 'Active';
}

export function isLocalImage(image: LocalImageDTO | ImageDTO | ItemPictureDTO): image is LocalImageDTO {
  // No fileId created so far
  return !image.hasOwnProperty('fileId');
}
