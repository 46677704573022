import { createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { RootState } from 'features';

type BusinessState = {
  profile: FullBusinessDTO | null;
  isChecked: boolean; // flag if user needs to create his first business
  businessLoading: boolean; // flag if user needs to create his first business
};

// Define the initial state using that type
const initialState: BusinessState = {
  profile: null,
  isChecked: false,
  businessLoading: true,
};

export const businessSlice = createSlice({
  name: 'business',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearCurrentBusiness(state) {
      state.profile = null;
    },
    setBusinessCheck(state, action) {
      state.isChecked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.createBusiness.matchFulfilled, (state, { payload }) => {
      state.profile = payload;
      state.isChecked = true;
    });
    builder.addMatcher(api.endpoints.getBusiness.matchPending, (state, { payload }) => {
      state.businessLoading = true;
    });
    builder.addMatcher(api.endpoints.getBusiness.matchFulfilled, (state, { payload }) => {
      state.profile = payload.business;
      state.isChecked = true;
      state.businessLoading = false;
    });
    builder.addMatcher(api.endpoints.getBusiness.matchRejected, (state, { payload }) => {
      if (payload?.status === 409) {
        // user exists, but created no business yet
        state.isChecked = true;
        state.businessLoading = false;
      }
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const businessSelectors = {
  business: (state: RootState) => state.business.profile,
  isBusinessChecked: (state: RootState) => state.business.isChecked,
  businessLoading: (state: RootState) => state.business.businessLoading,
};

export default businessSlice;
