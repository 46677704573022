import { SettingsBox, WebsiteBox } from './styles';

const LightMode = () => {
  return (
    <SettingsBox>
      <WebsiteBox />
      <WebsiteBox />
      <WebsiteBox />
      <WebsiteBox sx={{ mb: 0 }} />
    </SettingsBox>
  );
};
export default LightMode;
