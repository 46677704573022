import React from 'react';
import { Grid } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import EnterEmail from './EnterEmail';
import { CenteredBox } from 'styles';
import { FullSizeBox } from './styles';
import EmailVerification from './EmailVerification';
import CreatePassword from './CreatePassword';
import EnterPassword from './EnterPassword';
import NewPasswordStep from './PasswordReset/NewPasswordStep';
import SuccessStep from './PasswordReset/SuccessStep';
import PasswordResetEmail from './PasswordReset/PasswordResetEmail';
import NotFound from 'pages/NotFound';

const Auth = () => {
  // const navigate = useNavigate();
  // const { state, pathname } = useLocation();
  // useEffect(() => {
  //   if (pathname !== '/auth/email' && pathname !== '/auth/passworResetdEmail' && !state?.email) {
  //     navigate('/');
  //   }
  // }, [navigate]);

  return (
    <FullSizeBox>
      <Grid container sx={{ height: '100%' }}>
        <Grid item md={12} lg={12} xs={12} sm={12}>
          <CenteredBox>
            <Routes>
              <Route path="email" element={<EnterEmail />} />
              <Route path="emailVerify" element={<EmailVerification />} />
              <Route path="createPassword" element={<CreatePassword />} />
              <Route path="enterPassword" element={<EnterPassword />} />
              <Route path="passwordResetNew" element={<NewPasswordStep />} />
              <Route path="passworResetdEmail" element={<PasswordResetEmail />} />
              <Route path="success" element={<SuccessStep />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </CenteredBox>
        </Grid>
      </Grid>
    </FullSizeBox>
  );
};
export default Auth;
