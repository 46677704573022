import { SettingsBoxDark, WebsiteBoxDark } from './styles';

const DarkMode = () => {
  return (
    <SettingsBoxDark>
      <WebsiteBoxDark />
      <WebsiteBoxDark />
      <WebsiteBoxDark />
      <WebsiteBoxDark sx={{ mb: 0 }} />
    </SettingsBoxDark>
  );
};
export default DarkMode;
