import * as yup from 'yup';

const REQUIRED_MESSAGE = 'The field is required';
const PHONE_NUMBER_LENGTH_MESSAGE = 'Phone Number must be at least 10 numbers';
export const businessSchema = yup.object({
  name: yup.string().required(REQUIRED_MESSAGE),
  address: yup.string().optional(),
  description: yup.string().optional(),
  phoneNumber: yup
    .string()
    .test('phone', PHONE_NUMBER_LENGTH_MESSAGE, (value) => {
      const valueLength = value && value.replace(/[^\w\s]/gi, '').replaceAll(' ', '').length;
      let tested = true;
      tested = !(valueLength && valueLength < 10 && valueLength !== 0);
      return tested;
    })
    .test('phone', REQUIRED_MESSAGE, (value) => {
      const valueLength = value && value.replace(/[^\w\s]/gi, '').replaceAll(' ', '').length;
      let tested = true;
      if (!valueLength) {
        tested = false;
      } else {
        tested = true;
      }
      return tested;
    })
    .required(REQUIRED_MESSAGE)
    .trim(),
  logo: yup.string().nullable().optional(),
  currency: yup.string().required(),
});
