import React, { useEffect, useState } from 'react';
import { MenuTitle } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledForm } from 'pages/Authenticate/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, MenuItem, Typography } from '@mui/material';
import LoadingButton from 'components/UI/LoadingButton';
import { configSelectors, configSlice } from 'features/config';
import PreviewImage from 'components/ImageUpload/PreviewImage';
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { StyledFooter } from 'components/UI/LoadingButton/styles';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import GoogleAddressAutoComplete from 'components/UI/GoogleAddressAutoComplete/GoogleAddressAutoComplete';
import { useLazyUploadPictureQuery, useUpdateBusinessMutation, useUpdateSettingsMutation } from 'api/index';
import { useLocation } from 'react-router-dom';
import { businessSchema } from './businessSchema';

const EditBusinessProfile = ({ businessData, currenciesList, settings }: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const haveFormChange = useAppSelector(configSelectors.haveFormChange);
  const [updateBusiness, { isLoading: isUpdateBusinessLoading }] = useUpdateBusinessMutation();
  const [updateSettings, { isLoading: isUpdateCurrenciesLoading }] = useUpdateSettingsMutation();
  const [uploadImage, { isFetching: isImageLoading }] = useLazyUploadPictureQuery();
  const [images, setImages] = useState<string | any>('');
  const [imageURLs, setImageURLs] = useState<string | any>('');
  const [imageIsTouched, setImageIsTouched] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<string>('');
  // const [createBusiness, { isLoading: isBusinessLoading }] = useCreateBusinessMutation();
  const [addressTouched, setAddressTouched] = useState<boolean | any>(false);
  const updateBusinessProfile = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      logo: '',
      phoneNumber: '',
      address: '',
      description: '',
      currency: '',
    },
    resolver: yupResolver(businessSchema),
  });

  useEffect(() => {
    if (!haveFormChange && updateBusinessProfile.formState.isDirty) {
      dispatch(configSlice.actions.formChangeWatcher(updateBusinessProfile.formState.isDirty));
    }
    if (imageIsTouched) {
      dispatch(configSlice.actions.formChangeWatcher(imageIsTouched));
    }
    if (addressTouched) {
      dispatch(configSlice.actions.formChangeWatcher(addressTouched));
    }
  }, [updateBusinessProfile.formState.isDirty, imageIsTouched, addressTouched]);

  useEffect(() => {
    if (businessData && businessData?.business && currenciesList && currenciesList.length) {
      updateBusinessProfile.reset({
        name: businessData?.business?.name,
        description: businessData?.business?.description,
        phoneNumber: businessData?.business?.phoneNumber,
        address: businessData?.business?.address,
        currency: currenciesList.find((curr: any) => curr.id === settings?.menuCurrencyId)?.id,
      });
      dispatch(configSlice.actions.formChangeWatcher(false));
      setImageURLs(businessData?.business?.logo?.url);
      if (businessData?.business && businessData?.business?.logo?.fileId) {
        localStorage.setItem('fileId', businessData?.business?.logo?.fileId);
      } else {
        localStorage.removeItem('fileId');
      }
    }
  }, [businessData, settings, currenciesList]);

  useEffect(() => {
    if (images) {
      setImageIsTouched(true);
      setImageURLs(URL.createObjectURL(images));
    }
  }, [images]);
  // const isFormDirty = updateBusinessProfile.formState.isDirty;

  const handleSubmit = async (values: any) => {
    if (!values?.address) {
      setAddressError('The field is required');
      return;
    }
    if (typeof values.phoneNumber !== 'number') {
      values['formattedPhoneNumber'] = values.phoneNumber;
    }

    dispatch(configSlice.actions.formChangeWatcher(false));
    setAddressTouched(false);
    setAddressError('');
    let fileId = localStorage.getItem('fileId');
    let imageData: any;
    if (images) {
      imageData = await handleUploadImage(images);
      localStorage.setItem('fileId', imageData?.data?.logo?.fileId);
      values['logo'] = imageData?.data;
    } else if (!images && fileId) {
      values['logo'] = businessData?.business?.logo;
    } else {
      values['logo'] = null;
    }

    try {
      await updateBusiness({
        ...values,
        name: values.name,
        logo: values.logo,
        address: values.address,
        phoneNumber: values.phoneNumber.replace(/[^+\d]/g, ''),
        description: values.description,
      }).unwrap();
      await updateSettings({
        websiteMode: settings?.websiteMode ?? 'light',
        menuCurrencyId: values.currency,
      }).unwrap();
      setImageIsTouched(false);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const handleError = () => {
    let address = updateBusinessProfile.getValues('address');
    if (!address) {
      setAddressError('The field is required');
    } else {
      setAddressError('');
    }
  };
  const handleDelete = () => {
    setImages('');
    setImageURLs('');
    setImageIsTouched(true);
    localStorage.removeItem('fileId');
  };
  const setAddressInfo = (dataModel: any) => {
    if (dataModel) {
      dataModel && setAddressError('');
      updateBusinessProfile.setValue('address', dataModel?.fullAddress);
      setAddressTouched(true);
    }
  };
  const handleUploadImagePreview = (event: any) => {
    if (!event.target.files) return;
    setImages(event.target.files[0]);
  };
  const handleUploadImage = async (image: any) => {
    const formData = new FormData();
    formData.append('file', image);
    try {
      return await uploadImage(formData);
    } catch (error) {
      throw new Error('Error on image upload');
    }
  };
  // const imageUpdate = imageURLs.length > 0 ? true : false;
  // console.log(imageUpdate, 'imageUpdateAAAAAAA');
  const isFormDirty = updateBusinessProfile.formState.isDirty || addressTouched;

  return (
    <FormProvider {...updateBusinessProfile}>
      <StyledForm onSubmit={updateBusinessProfile.handleSubmit(handleSubmit, handleError)}>
        <Box
          sx={{
            opacity: 1,
            height: '100%',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ maxWidth: '454px', p: 3 }}>
            <MenuTitle>Business information</MenuTitle>
            {imageURLs ? (
              <PreviewImage imageUrl={imageURLs} handleDelete={handleDelete} />
            ) : (
              <ImageUpload handleUploadImage={handleUploadImagePreview} />
            )}
            <CustomTextInput size="small" name="name" label="Restaurant name" />
            <CustomTextInput size="small" name="description" label="Description" multiline={true} rows={3} />
            <CustomTextInput size="small" name="phoneNumber" label="Phone Number" />
            <Box sx={{ mb: 1 }}>
              <GoogleAddressAutoComplete
                backgroundColor="#F4F4F4"
                setAddressInfo={setAddressInfo}
                currentAddress={updateBusinessProfile.getValues('address')}
                businessProfile={updateBusinessProfile}
                setAddressError={setAddressError}
              />
              {addressError && <Typography sx={{ color: 'red', fontSize: '12px' }}>{addressError}</Typography>}
            </Box>
            <CustomTextInput size="small" name="currency" label="Menu currency" select={true}>
              {currenciesList.length &&
                currenciesList.map((currencyItem: any, index: number) => {
                  return (
                    <MenuItem value={currencyItem?.id} key={`${currencyItem?.id}-${index}`} selected={true}>
                      {currencyItem?.currencyCode}
                    </MenuItem>
                  );
                })}
            </CustomTextInput>
            <StyledFooter>
              <LoadingButton
                isLoading={isUpdateBusinessLoading || isUpdateCurrenciesLoading || isImageLoading}
                sx={{ width: '454px' }}
                type={'submit'}
                variant={'contained'}
                disabled={
                  Boolean(isUpdateBusinessLoading || isUpdateCurrenciesLoading || isImageLoading) ||
                  (!isFormDirty && !imageIsTouched)
                }
              >
                Save
              </LoadingButton>
            </StyledFooter>
          </Box>
        </Box>
      </StyledForm>
    </FormProvider>
  );
};
export default EditBusinessProfile;
