import React, { useState } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import GoBack from '../GoBack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthBox, AuthSubTitle, AuthTitle, StyledForm, StyledFormBox } from '../styles';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyResetPasswordQuery } from 'api';
import { IResetPassword } from '../types';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from 'components/UI/LoadingButton';

const passwordSchema = yup.object({
  password: yup
    .string()
    .required('The field is required')
    .min(8, `Password must be at least 8 characters long`)
    .matches(/[a-z]/g, 'Include uppercase and lowercase letters (A, z)')
    .matches(/[A-Z]/g, 'Include uppercase and lowercase letters (A, z)')
    .matches(/[0-9]/g, 'Include Numeric characters (0-9)'),
  // .matches(/[^\w\s]/g, 'Include Special characters')
});

const NewPasswordStep = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [resetPassword, { isLoading }] = useLazyResetPasswordQuery();

  const defaultValuesEmail: IResetPassword = {
    password: '',
  };

  const enterEmailFormMethods = useForm({
    mode: 'all',
    defaultValues: defaultValuesEmail,
    resolver: yupResolver(passwordSchema),
  });

  const { handleSubmit } = enterEmailFormMethods;

  const onSubmit = async (data: IResetPassword) => {
    try {
      await resetPassword({
        uuid: '',
        newPassword: data.password,
        confirmPass: data.password,
        email: state.email,
      }).unwrap();
      navigate('/auth/enterPassword', { state: { email: state.email } });
    } catch (err) {
      console.log('err: ', err);
      if (err) {
      }
    }
  };
  const onError = (errors: any) => {
    console.log(errors);
  };

  const visiblePassword = () => setShowPassword((prevState) => !prevState);

  return (
    <Box>
      <AuthBox>
        <GoBack />
        <AuthTitle variant="h1">Reset password</AuthTitle>
        <AuthSubTitle> Enter your new password</AuthSubTitle>
        <StyledFormBox>
          <FormProvider {...enterEmailFormMethods}>
            <StyledForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CustomTextInput
                name="password"
                label="Password"
                sx={{ mt: 5, width: '100%' }}
                InputProps={{
                  type: showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton isLoading={isLoading} type="submit" sx={{ width: '100%' }} variant="contained">
                Set New Password
              </LoadingButton>
            </StyledForm>
          </FormProvider>
        </StyledFormBox>
      </AuthBox>
    </Box>
  );
};

export default NewPasswordStep;
