import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useMatchMobile } from 'hooks/useMatchMobile';
import notFound from 'assets/svg/NotFound.svg';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const { isMatchMobile } = useMatchMobile();

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/404');
  }, []);

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12} lg={12}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            px: 4,
            minHeight: !isMatchMobile ? 'calc(100vh - 86px - 86px)' : 0,
            mt: isMatchMobile ? '40px' : 0,
            height: '100vh',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: !isMatchMobile ? '96px' : '48px',
              fontWeight: 700,
            }}
          >
            Oops!
          </Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 700, my: 2 }}>Page Not Found</Typography>
          <Typography sx={{ width: '398px', textAlign: 'center', mb: 4 }}>
            {`We're sorry, but the page you were trying to reach doesn't exist on our
      site. It may have been moved, updated or deleted.`}
          </Typography>
          <img src={notFound} alt="Restaurant Not Found" width={!isMatchMobile ? 316 : 207} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotFound;
