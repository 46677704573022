import React from 'react';
import GoBack from '../GoBack';
import * as yup from 'yup';
import { AuthBox, AuthSubTitle, AuthTitle, StyledForm, StyledFormBox } from '../styles';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyForgotPasswordQuery } from 'api';
import { IEnterEmail } from '../types';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from 'components/UI/LoadingButton';

const schemaEnterEmail = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('This field is invalid')
      .matches(/^[a-zA-Z0-9._%+-]+@\w+\.[a-zA-Z]{2,}$/, 'Please enter a valid email address.')
      .required('This field is required')
      .trim(),
  })
  .required();

const PasswordResetEmail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [forgotPassword, { isLoading }] = useLazyForgotPasswordQuery();

  const defaultValuesEmail: IEnterEmail = {
    email: state.email,
  };

  const enterEmailFormMethods = useForm({
    mode: 'all',
    defaultValues: defaultValuesEmail,
    resolver: yupResolver(schemaEnterEmail),
  });

  const { handleSubmit } = enterEmailFormMethods;

  const onSubmit = async (data: IEnterEmail) => {
    try {
      await forgotPassword(data.email.toLowerCase()).unwrap();
      navigate('/auth/emailVerify', { state: { email: data.email, isNewUser: false } });
    } catch (error) {
      console.log(error);
    }
  };
  const onError = (errors: any) => {
    console.log(errors);
  };

  return (
    <Box>
      <AuthBox>
        <GoBack />
        <AuthTitle variant="h1">Reset password</AuthTitle>
        <AuthSubTitle>Enter your Email to receive instructions for resetting your password.</AuthSubTitle>
        <StyledFormBox>
          <FormProvider {...enterEmailFormMethods}>
            <StyledForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CustomTextInput name="email" label="Email address" sx={{ mt: 5, width: '100%' }} />
              <LoadingButton isLoading={isLoading} type="submit" sx={{ width: '100%' }} variant="contained">
                Reset
              </LoadingButton>
            </StyledForm>
          </FormProvider>
        </StyledFormBox>
      </AuthBox>
    </Box>
  );
};

export default PasswordResetEmail;
