import React from 'react';
import { Box } from '@mui/material';
import GoBack from './GoBack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthBox, AuthSubTitle, AuthTitle, StyledForm, StyledFormBox } from './styles';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { useNavigate } from 'react-router-dom';
import { useLazyCheckEmailQuery } from 'api';
import { IEnterEmail } from './types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import LoadingButton from 'components/UI/LoadingButton';

const schemaEnterEmail = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('This field is invalid')
      .matches(/^[a-zA-Z0-9._%+-]+@\w+\.[a-zA-Z]{2,}$/, 'Please enter a valid email address.')
      .required('This field is required')
      .trim(),
  })
  .required();

const Email: any = () => {
  const navigate = useNavigate();
  const [checkEmail, { isLoading }] = useLazyCheckEmailQuery();

  const defaultValuesEmail: IEnterEmail = {
    email: '',
  };

  const enterEmailFormMethods = useForm({
    mode: 'all',
    defaultValues: defaultValuesEmail,
    resolver: yupResolver(schemaEnterEmail),
  });

  const { handleSubmit } = enterEmailFormMethods;

  const onSubmit = async (data: IEnterEmail) => {
    try {
      await checkEmail(data.email.toLowerCase()).unwrap();
      navigate('/auth/emailVerify', { state: { email: data.email, isNewUser: true } });
    } catch (err) {
      if (err) {
        if (((err as FetchBaseQueryError)?.data as ErrorResponse)?.statusCode === 409) {
          navigate('/auth/enterPassword', { state: { email: data.email } });
        }
      }
    }
  };
  const onError = (errors: any) => {
    console.log(errors);
  };

  return (
    <Box>
      <AuthBox>
        <GoBack />
        <AuthTitle variant="h1">Enter your email address</AuthTitle>
        <AuthSubTitle>We will use this to sign you in or to create an account if you don’t have one yet.</AuthSubTitle>
        <StyledFormBox>
          <FormProvider {...enterEmailFormMethods}>
            <StyledForm onSubmit={handleSubmit(onSubmit, onError)}>
              <CustomTextInput
                inputProps={{
                  type: 'email',
                }}
                name="email"
                label="Email address"
                sx={{ mt: 5, width: '100%' }}
              />
              <LoadingButton isLoading={isLoading} type="submit" sx={{ width: '100%' }} variant="contained">
                Continue
              </LoadingButton>
            </StyledForm>
          </FormProvider>
        </StyledFormBox>
      </AuthBox>
    </Box>
  );
};

export default Email;
