import React from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import goBack from 'assets/svg/goBack.svg';
import { useNavigate } from 'react-router-dom';

const GoBack: any = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <IconButton onClick={handleGoBack}>
        <img src={goBack} alt="Go back" />
      </IconButton>
    </Box>
  );
};

export default GoBack;
