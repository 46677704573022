// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { arrayMove } from 'react-sortable-hoc';
import { Box, CircularProgress, MenuItem, Typography, Button } from '@mui/material';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { defaultPriceValue, defaultValues, schemaItem } from './itemSchema';
import { STATUS } from 'constants/global';
import { StyledFooterSettings } from 'pages/WebsiteSettings/styles';
import CustomMultiselect from 'components/FormFields/CustomMultiselect';
import {
  useDeleteItemImageMutation,
  useDeleteItemMutation,
  useGetCategoryListQuery,
  useGetClassificationsQuery,
  useGetItemQuery,
  useGetMenuListQuery,
  useLazyUpdateAllItemImagesQuery,
  useLazyUploadPictureQuery,
  useUpdateItemMutation,
} from 'api';
import ItemImage from 'components/ItemImage';
import { getBoolStatusValue, isLocalImage } from 'utils/uploadImage';
import { useAppSelector } from 'hooks/useReduxHook';
import { businessSelectors } from 'features/business';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from 'components/UI/LoadingButton';
import { useDispatch } from 'react-redux';
import SortableList from './SoratbleItem';
import configSlice, { configSelectors } from 'features/config';
import ConfirmableModal from 'components/Modals/ConfirmableModal';
import { useDeleteCategoryMutation } from 'api';
// import { menuDataSelectors } from 'features/menuData';
const EditItem = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const haveFormChange = useAppSelector(configSelectors.haveFormChange);
  const [searchParams] = useSearchParams();
  let id = searchParams.get('id');
  const { data: classifications } = useGetClassificationsQuery(undefined);
  const businessId = useAppSelector(businessSelectors.business)?.id ?? '';
  const { data: menuList } = useGetMenuListQuery(businessId, { skip: !businessId });
  const [uploadImage] = useLazyUploadPictureQuery();
  const [updateItem, { isLoading: isUpdateLoading }] = useUpdateItemMutation();
  const { data: currentItem, isLoading: isGetItemLoading } = useGetItemQuery(id, { skip: !id });
  const [updateItemImages] = useLazyUpdateAllItemImagesQuery();
  const [deleteItemImage] = useDeleteItemImageMutation();
  const [images, setImages] = useState<any>([]);
  const [value, setValue] = useState<any>([]);
  const [defaultImage, setDefaultImage] = useState(0);
  const [isLoadingItem, setLoadingItems] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [imageTouched, setImageTouched] = useState<boolean>(false);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);
  const [deleteCategoryQuery] = useDeleteCategoryMutation();
  const navigate = useNavigate();
  const [deleteItemQuery, { isLoading: isDeleteLoading }] = useDeleteItemMutation();

  // const categoryList = useAppSelector(menuDataSelectors.allCategories);
  const itemForm = useForm({
    mode: 'all',
    defaultValues: defaultValues,
    resolver: yupResolver(schemaItem),
  });

  const { control, watch, reset } = itemForm;
  const { fields, append, update } = useFieldArray({
    control,
    name: 'price',
  });
  const menuId = watch('menuId');

  const { data: categoryList, isLoading: isCatLoading } = useGetCategoryListQuery(menuId, { skip: !menuId });

  useEffect(() => {
    if (currentItem) {
      setImages(currentItem?.pictures);
      const editData = {
        ...currentItem,
        menuId: currentItem.menuId,
        category: currentItem.categoryId,
        status: currentItem.status ? 'Active' : 'Disabled',
      };

      reset({ ...editData, price: currentItem.price });
      const findIndex = currentItem?.pictures?.findIndex((item) => item.default);
      setDefaultImage(findIndex !== -1 ? findIndex : 0);
      setValue(currentItem?.pictures);
    }
  }, [categoryList, currentItem, dispatch, location, reset]);

  useEffect(() => {
    if (!haveFormChange) {
      dispatch(configSlice.actions.formChangeWatcher(itemForm.formState.isDirty));
    }
    if (!haveFormChange && imageTouched) {
      dispatch(configSlice.actions.formChangeWatcher(Boolean(imageTouched)));
    }
  }, [itemForm.formState.isDirty, imageTouched]);

  const handleAddPrice = useCallback(() => {
    append(defaultPriceValue);
  }, [append]);

  const handleRemovePrice = useCallback(
    (id: number) => {
      fields.splice(id, 1);
      itemForm.setValue('price', fields, { shouldDirty: true });
      reset({ ...itemForm.getValues(), price: fields }, { keepDirty: true });
    },
    [fields, reset, itemForm],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortData = arrayMove(itemForm.getValues()?.price || fields, oldIndex, newIndex);
    itemForm.setValue('price', sortData, { shouldDirty: true });
    reset({ ...itemForm.getValues(), price: sortData }, { keepDirty: true });
  };

  const handleSubmit = useCallback(
    async (values: any) => {
      setLoadingItems(true);
      const deletePromises: Array<Promise<undefined>> = [];
      const uploadPromises: Array<Promise<ImageDTO> | ImageDTO> = [];

      // Delete removed images from DB
      const remainedPictures: ItemPictureDTO[] = images && images.filter(
        (picture) => picture && !isLocalImage(picture),
      ) as ItemPictureDTO[];

      const picturesToDelete = currentItem!.pictures?.filter(
        (oldPicture) =>
          remainedPictures.findIndex((newPicture: ItemPictureDTO) => newPicture.fileId === oldPicture.fileId) === -1,
      );
      picturesToDelete?.forEach((picture) =>
        deletePromises.push(
          deleteItemImage({
            id: currentItem!.id,
            imgId: picture.fileId,
          }).unwrap(),
        ),
      );
      await Promise.all(deletePromises);

      for (let i = 0; i < images?.length; i++) {
        if (isLocalImage(images[i])) {
          const formData = new FormData();
          formData.append('file', images[i]);
          const result = await uploadImage(formData).unwrap();
          uploadPromises.push(result);
        } else {
          uploadPromises.push(images[i]);
        }
      }

      const uploadedImages = await Promise.all(uploadPromises);
      const itemImages: ItemPictureDTO[] = uploadedImages.map((image, index) => ({
        ...image,
        default: defaultImage ? index === defaultImage : index === 0,
      }));

      setTimeout(async () => {
        await updateItemImages({ data: itemImages, itemId: currentItem!.id });
      });

      updateItem({
        data: {
          name: values.name,
          description: values.description,
          menuId: values.menuId,
          categoryId: values.category,
          price: values.price,
          status: getBoolStatusValue(values.status),
          sort: currentItem!.sort,
          calories: values.calories ? Number(values.calories) : undefined,
          classification: values.classifications,
        },
        id: currentItem!.id,
      })
        .then(() => {
          navigator(-1);
          setLoadingItems(false);
          dispatch(configSlice.actions.formChangeWatcher(false));
        })
        .catch(() => {
          setLoadingItems(false);
        });
    },
    [images, defaultImage, currentItem],
  );
  const isFormDirty = itemForm.formState.isDirty || imageTouched;
  const handleDelete = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirm = async () => {
    await deleteItemQuery(id);
    setOpenConfirmModal(false);
    dispatch(configSlice.actions.formChangeWatcher(false));
    navigate(-1);
  };
  return (
    <FormProvider {...itemForm}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 3 }}>
        {isCatLoading || isGetItemLoading || isUpdateLoading ? (
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 74px - 84px)',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          // <Grid container spacing={2} sx={{ px: 3 }}>
          //   <Grid item sm={12} xs={12} md={12} lg={8}>
          <Box component="form" maxWidth={750} mt={1}>
            <>
              {/* <Grid container columnSpacing={1} rowSpacing={0.875} flexDirection="column" mb={3}>
                <Grid item> */}
              <Typography mt={3} mb={2}>
                <Box component="strong">Item photo</Box> (Add up to 3 images)
              </Typography>
              <ItemImage
                setDefaultImage={setDefaultImage}
                setImages={setImages}
                images={images}
                defaultImage={defaultImage}
                value={value}
                setValue={setValue}
                itemForm={itemForm}
                setImageTouched={setImageTouched}
              />
              {/* </Grid>
                <Grid item> */}
              <CustomTextInput size="small" name="name" label="Name" />
              {/* </Grid>
                <Grid item> */}
              <CustomTextInput size="small" name="description" label="Description" multiline={true} rows={3} />
              {/* </Grid>
                <Grid item> */}
              <CustomTextInput size="small" name="status" label="Status" select={true}>
                {STATUS.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </CustomTextInput>
              {/* </Grid>
                <Grid item> */}
              <CustomTextInput size="small" name="menuId" label="Choose menu" select={true}>
                {menuList?.map((menu) => {
                  return (
                    <MenuItem key={menu.id} value={menu.id}>
                      {menu.name}
                    </MenuItem>
                  );
                })}
              </CustomTextInput>
              {/* </Grid>
                <Grid item> */}
              <CustomTextInput
                size="small"
                name="category"
                label={`${menuId ? 'Choose category' : 'No available options yet'}`}
                select={Boolean(menuId)}
                disabled={Boolean(!menuId)}
              >
                {categoryList?.map((category: any) => {
                  return (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  );
                })}
              </CustomTextInput>
              {/* </Grid>
                <Grid item> */}
              <CustomTextInput
                size="small"
                inputProps={{
                  type: 'number',
                }}
                name="calories"
                label="Calories"
              />
              {/* </Grid>
                <Grid item> */}
              <CustomMultiselect
                // sx={{ mt: 2 }}
                name="classification"
                label="Classification"
                options={classifications || []}
                multiple
                defaultValues={classifications?.[0] || []}
              />
              {/* </Grid>
              </Grid> */}
              <Box sx={{ mt: 2 }}>
                <SortableList
                  itemForm={itemForm}
                  handleRemovePrice={handleRemovePrice}
                  useDragHandle
                  items={fields}
                  onSortEnd={onSortEnd}
                />
              </Box>
              {/* <Grid container columnSpacing={2} mb={4}>
                <Grid item> */}
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                fontSize={20}
                fontWeight={500}
                color="#007DFF"
                lineHeight="26px"
                onClick={handleAddPrice}
              >
                + Add price
              </Typography>
              {/* </Grid>
              </Grid> */}
            </>
            <StyledFooterSettings>
              <Button sx={{ width: '375px', mr: 5 }} variant={'contained'} color="error" onClick={handleDelete}>
                Delete
              </Button>
              <ConfirmableModal
                open={openConfirmModal}
                onClose={handleCloseConfirmModal}
                onConfirm={handleConfirm}
                title={'Delete'}
                message={'Are you sure you want to delete this item?'}
              />
              <LoadingButton
                disabled={!isFormDirty}
                isLoading={isLoadingItem}
                sx={{ width: '375px' }}
                type="submit"
                variant="contained"
                onClick={itemForm.handleSubmit(handleSubmit)}
              >
                Update
              </LoadingButton>
            </StyledFooterSettings>
          </Box>
          //   </Grid>
          // </Grid>
        )}
      </Box>
    </FormProvider>
  );
};

export default EditItem;
