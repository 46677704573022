import { Box, styled } from '@mui/material';

export const WebsiteBox = styled(Box)({
  width: '100%',
  height: '20px',
  backgroundColor: '#F2F2F2',
  borderRadius: '4px',
  marginBottom: '8px',
});
export const WebsiteBoxDark = styled(Box)({
  width: '100%',
  height: '20px',
  background: '#F2F2F2',
  borderRadius: '4px',
  marginBottom: '8px',
});

export const SettingsBox = styled(Box)(({ theme }) => ({
  width: '330px',
  background: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',

  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '16px',
  [theme.breakpoints.down('sm')]: {
    width: '280px',
    maxWidth: '280px',
  }
})) as typeof Box;

export const SettingsBoxDark = styled(Box)(({ theme }) => ({
  width: '330px',
  backgroundColor: '#3C4351',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '16px',
  [theme.breakpoints.down('sm')]: {
    width: '280px',
    maxWidth: '280px',
  }
})) as typeof Box;

export const SettingsBoxRadio = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});
