import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDeleteItemMutation, useGetMenuListQuery, useLazyGetCategoriesWithItemsQuery } from 'api';
import { SearchInput } from 'components/Menu/styles';
import { categoriesSelectors } from 'features/categories';
import { useAppSelector } from 'hooks/useReduxHook';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Grid, Button, InputAdornment, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { businessSelectors } from 'features/business';
import Preloader from 'components/UI/Preloader/Preloader';
import Item from 'components/MenuItem/Item';
import { cloneDeep } from 'lodash';
import { WelcomePage } from 'components/UI/WelcomePage';

const Items = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [itemsListData, setItemsListData] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const businessId = useAppSelector(businessSelectors.business)?.id;
  const currentMenuId = useAppSelector(categoriesSelectors.selectedMenuId);
  const { data: menuList } = useGetMenuListQuery(businessId as string, { skip: !businessId });
  const [getCategoriesWithProduct] = useLazyGetCategoriesWithItemsQuery();
  const [deleteItemQuery, { isLoading: isDeleteLoading }] = useDeleteItemMutation();

  const currentMenuName = useMemo(() => {
    if (currentMenuId && menuList && menuList.length) {
      return menuList.find((menu: any) => menu?.id === currentMenuId)?.name;
    }
  }, [menuList, currentMenuId]);

  const deleteItem = async (id) => {
    await deleteItemQuery(id);
    if (searchValue) {
      const newList = searchList.filter((item) => item.id !== id);
      setSearchList(newList);
    } else {
      const newList = itemsListData.filter((item) => item.id !== id);
      setItemsListData(newList);
    }
  };

  const storeProductsFromCategories = (data) => {
    const lists = [];
    data.forEach((catItem) => {
      const catName = catItem.name;
      if (catItem.products.length) {
        const currentProductList = cloneDeep(catItem.products);
        currentProductList.forEach((item) => {
          item.categoryName = catName;
        });
        lists.push(...currentProductList);
      }
    });
    const sortedList = lists.sort((a, b) => a.name.localeCompare(b.name));
    return sortedList;
  };

  useEffect(() => {
    setLoading(true);
    if (menuList?.length) {
      const promises: any = [];

      menuList.forEach((manuItem) => {
        promises.push(getCategoriesWithProduct(manuItem.id));
      });
      Promise.all(promises)
        .then((res: any) => {
          const listProducts = [];
          res.forEach((resItem) => {
            const categoryProducts = storeProductsFromCategories(resItem?.data);
            listProducts.push(...categoryProducts);
          });

          setItemsListData(listProducts);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (menuList) {
        setLoading(false);
      }
    }
  }, [getCategoriesWithProduct, menuList]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    let search = e.target.value;
    setSearchValue(search);
    if (search) {
      let filterItems: any = [];
      itemsListData.forEach((menu) => {
        if (
          menu?.name.toLowerCase().includes(search.trim().toLowerCase()) ||
          menu?.description.toLowerCase().includes(search.trim().toLowerCase())
        ) {
          filterItems.push(menu);
        }
      });
      setSearchList(filterItems);
    } else {
      setItemsListData(itemsListData);
    }
  };

  const clearValue = () => {
    setSearchValue('');
  };

  if (loading) {
    return <Preloader overlay sx={{ margin: 0 }} />;
  }

  if (!itemsListData.length) {
    return (
      <WelcomePage
        title="Items will appear here"
        description="Click on the PLUS icon below to create your first item."
        url={'/items/create'}
      />
    );
  }

  return (
    <Grid container spacing={2} sx={{ p: 3 }}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          <SearchInput
            size="small"
            value={searchValue}
            onChange={handleInput}
            label={'Search items'}
            placeholder={'Search items...'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue.length ? (
                    <IconButton size={'small'} onClick={clearValue}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </InputAdornment>
              ),
              sx: {
                width: '100%',
                background: 'white',
                minHeight: '48px',
              },
            }}
          />
          <Button
            sx={{ minWidth: '140px', ml: 2 }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/items/create')}
          >
            Add New
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {loading && <Preloader overlay sx={{ margin: 0 }} />}

        {searchValue && searchList.length ? (
          searchList.map((itemData: any) => (
            <Box key={itemData.id} sx={{ mb: 2 }}>
              <Item deleteItem={deleteItem} currentMenuName={currentMenuName} item={itemData} />
            </Box>
          ))
        ) : searchValue ? (
          <Typography sx={{ textAlign: 'center' }}>No items found for your search.</Typography>
        ) : (
          ''
        )}
        {!searchValue && itemsListData.length ? (
          itemsListData.map((itemData: any) => (
            <Box key={itemData.id} sx={{ mb: 2 }}>
              <Item deleteItem={deleteItem} currentMenuName={currentMenuName} item={itemData} />
            </Box>
          ))
        ) : !searchValue ? (
          <Typography sx={{ textAlign: 'center' }}>There is no any items.</Typography>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default Items;
