import {Box, Typography} from "@mui/material";
import { styled } from "@mui/material/styles";
export const PopoverContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  width: '100%',
  padding: theme.spacing(2, 8, 2, 0) ,
})) as typeof Box;

export const PopoverContentEditWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  paddingLeft: theme.spacing(2 )
})) as typeof Box;

export const PopoverContentDeleteWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1, 0, 0, 2 )
})) as typeof Box;

export const PopoverActionContent = styled(Typography)(({  }) => ({
  fontWeight: 600,
  fontSize: '14px',
  color: '#000000'
})) as typeof Box;
