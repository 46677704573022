import { TextField, List, ListItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SearchInput = styled(TextField)(({ theme }) => ({
  borderRadius: "4px",
  fontFamily: "Roboto",
  background: "white",
  width: "100%",
  // border: '1px solid, gray',
  marginBottom: theme.spacing(2),
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    ">div": {
      height: "48px",
    },
  },
})) as typeof TextField;

export const SearchContent = styled(List)(({ theme }) => ({
  backgroundColor: "white",
  padding: 0,
  borderRadius: "8px",
  width: "100%",
  position: "relative",
  maxHeight: '260px',
  overflowY: 'auto',
  marginTop: theme.spacing(1),
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#0F1214',
    outline: '1px solid #0F1214',
  },
})) as typeof List;

export const GoogleAddressDescriptionWrapper = styled(ListItem)(({  }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  paddingRight: "10px",
  maxWidth: "420px",
  alignItems: "start",
}));

export const GoogleAddressTitle = styled(Typography)(({  }) => ({
  fontWeight: 500,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  paddingRight: "10px",
  maxWidth: "420px",
  fontSize: "14px",
})) as typeof Typography;

export const GoogleAddressFormatTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  paddingRight: "10px",
  maxWidth: "420px",
  fontSize: "12px",
  paddingTop: theme.spacing(0.5),
})) as typeof Typography;

export const GoogleAddressPowerBy = styled(Typography)(({ theme }) => ({
  width: "111px",
  height: "16px",
  margin: theme.spacing(0, 0, 2, 2),
})) as typeof Typography;