import React, { useRef } from 'react';
import { get } from 'lodash';
import { TextField } from '@mui/material';
import { CustomTextInputI } from './types';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Controller, useFormContext } from 'react-hook-form';
import { onWheelPreventChangeNumberField } from 'utils/itmes';
const CustomTextInput = ({
  name,
  id,
  sx = {
    width: '100%',
    pb: 2,
  },
  children,
  label = '',
  validate,
  inputProps,
  hidden,
  onChange,
  disabled,
  multiline,
  rows,
  select = false,
  InputProps,
  size = 'medium',
  labelProps = {},
  isRef = false,
  ...params
}: CustomTextInputI) => {
  const ref = useRef(null);
  const { control, formState, register } = useFormContext();
  const { errors } = formState;
  const parsedError = get(errors, name);
  return (
    <Controller
      name={name}
      control={control}
      {...params}
      render={({ field }) => {
        if (field?.name?.includes('phone') || field?.name?.includes('phoneNumber')) {
          return (
            <MuiPhoneNumber
              defaultCountry={'us'}
              variant="outlined"
              {...field}
              size={size}
              className="Phone-number"
              hidden={hidden}
              error={!!parsedError || params.error || false}
              helperText={parsedError ? parsedError.message : params.helperText || ''}
              label={label}
              disabled={disabled}
              InputProps={InputProps}
              sx={sx}
              inputProps={inputProps}
              InputLabelProps={labelProps}
              ref={isRef ? register(name)?.ref : null}
            />
          );
        } else {
          return (
            <TextField
              {...field}
              size={size}
              hidden={hidden}
              error={!!parsedError || params.error || false}
              helperText={parsedError ? parsedError.message : params.helperText || ''}
              label={label}
              onWheel={onWheelPreventChangeNumberField}
              inputRef={field.ref}
              disabled={disabled}
              InputProps={{ ...InputProps }}
              sx={sx}
              multiline={multiline}
              rows={rows}
              select={select}
              inputProps={inputProps}
              onChange={onChange || field.onChange}
              InputLabelProps={labelProps}
            >
              {children}
            </TextField>
          );
        }
      }}
    />
  );
};

export default CustomTextInput;
