import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { businessSelectors } from 'features/business';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledForm } from 'pages/Authenticate/styles';
import LoadingButton from 'components/UI/LoadingButton';
import { FormProvider, useForm } from 'react-hook-form';
import { categoriesSelectors } from 'features/categories';
import { Box, Button, MenuItem } from '@mui/material';
import { getBoolStatusValue } from 'utils/getBoolStatusValue';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyledFooter } from 'components/UI/LoadingButton/styles';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { useDeleteCategoryMutation } from 'api';
import { menuDataSelectors } from 'features/menuData';
import { updateAllCategories } from 'features/menuData';

// import CategoryImageUpload from 'components/ImageUpload/CategoryImageUpload';
import { categorySchema } from 'components/Categories/CategoriesForm/categorySchema';
import {
  useGetMenuListQuery,
  // useLazyUploadPictureQuery,
  useCreateCategoryMutation,
  useGetCategoryQuery,
  // useLazyDeletePictureQuery,
  useUpdateCategoryMutation,
} from 'api';
import configSlice, { configSelectors } from 'features/config';
import ConfirmableModal from 'components/Modals/ConfirmableModal';

const CategoriesForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const haveFormChange = useAppSelector(configSelectors.haveFormChange);
  const [searchParams] = useSearchParams();
  let categoryId = searchParams.get('id');
  const currentMenuId = useAppSelector(categoriesSelectors.selectedMenuId);
  const businessId = useAppSelector(businessSelectors.business)?.id;
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);
  const [deleteCategoryQuery] = useDeleteCategoryMutation();
  const categoryList = useAppSelector(menuDataSelectors.allCategories);

  // const [files, setFiles] = useState('');
  // const [imageURLs, setImageURLs] = useState<string | any>('');
  // const [imageIsTouched, setImageIsTouched] = useState<boolean>(false);
  // const [uploadImage] = useLazyUploadPictureQuery();
  // const [deleteImage] = useLazyDeletePictureQuery();
  const [createCategory, { isLoading: isCreateLoading }] = useCreateCategoryMutation();
  const [updateCategory, { isLoading: isUpdateLoading }] = useUpdateCategoryMutation();
  const { data: currentCategory, isFetching: isGetCategoryLoading } = useGetCategoryQuery(categoryId as string, {
    skip: !categoryId,
  });
  const { data: menuList, isFetching: isLoading } = useGetMenuListQuery(businessId as string, { skip: !businessId });
  const categoryForm = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      menuId: '',
      status: 'Active' as 'Active' | 'Disabled',
    },
    resolver: yupResolver(categorySchema),
  });

  useEffect(() => {
    if (currentCategory && currentMenuId) {
      categoryForm.reset({
        name: currentCategory?.name,
        description: currentCategory?.description,
        status: currentCategory?.status ? 'Active' : 'Disabled',
        menuId: currentCategory?.menuId ?? '',
      });
      // setImageURLs(currentCategory?.pictures?.url);
      // if (currentCategory && currentCategory?.pictures?.fileId) {
      //   localStorage.setItem('categoryFileId', currentCategory?.pictures?.fileId);
      // }
    } else if (menuList && !currentCategory && menuList?.length === 1) {
      categoryForm.reset({
        ...categoryForm.getValues(),
        menuId: currentMenuId ?? '',
      });
    }
  }, [currentCategory, currentMenuId]);

  // @ts-ignore
  // const handleDropImage = (event: ChangeEvent<HTMLInputElement> | any) => {
  //   event.preventDefault();
  //   event.persist();
  //   if (event.target.files && !event.dataTransfer?.files) {
  //     setFiles(event.target.files[0]);
  //   } else if (event.dataTransfer?.files && !event.target.files) {
  //     setFiles(event.dataTransfer?.files[0]);
  //   }
  // };

  // useEffect(() => {
  //   if (files) {
  //     setImageIsTouched(true);
  //     // @ts-ignore
  //     setImageURLs(URL.createObjectURL(files));
  //   }
  // }, [files]);

  const handleSubmit = async (values: any) => {
    // let filedId = localStorage.getItem('categoryFileId');
    let imageData: any;
    dispatch(configSlice.actions.formChangeWatcher(false));

    // if (files) {
    //   imageData = await handleUploadImage(files);
    // }
    if (categoryId) {
      await updateCategory({
        data: {
          name: values.name,
          description: values.description,
          pictures: imageData?.data ?? null,
          menuId: values.menuId,
          status: getBoolStatusValue(values.status),
          sort: currentCategory!.sort,
        },
        id: currentCategory!.id,
      }).unwrap();
    } else {
      await createCategory({
        name: values.name,
        description: values.description,
        pictures: imageData?.data ?? null,
        menuId: values.menuId,
        status: getBoolStatusValue(values.status),
      }).unwrap();
    }
    navigate('/categories');
  };

  useEffect(() => {
    if (!haveFormChange) {
      dispatch(configSlice.actions.formChangeWatcher(categoryForm.formState.isDirty));
    }
  }, [categoryForm.formState.isDirty]);

  // const handleUploadImage = async (image: any) => {
  //   const formData = new FormData();
  //   formData.append('file', image);
  //   try {
  //     return await uploadImage(formData);
  //   } catch (error) {
  //     throw new Error('Error on image upload');
  //   }
  // };

  // const handleDelete = async () => {
  //   setFiles('');
  //   setImageURLs('');
  //   let fileId = localStorage.getItem('categoryFileId');
  //   if (fileId && currentCategory && currentCategory?.pictures?.fileId) {
  //     await deleteImage({
  //       uuid: fileId,
  //       type: 'category',
  //       typeId: currentCategory!.id,
  //     }).unwrap();
  //     localStorage.removeItem('categoryFileId');
  //   }
  // };
  const handleDelete = () => {
    setOpenConfirmModal(true);
  };
  const handleConfirm = async () => {
    await deleteCategoryQuery(categoryId).unwrap();
    dispatch(configSlice.actions.formChangeWatcher(false));
    const newList = categoryList.filter((item) => item.id !== categoryId);
    dispatch(updateAllCategories(newList));
    setOpenConfirmModal(false);
    navigate(-1);
  };
  const isFormDirty = categoryForm.formState.isDirty;

  return (
    <FormProvider {...categoryForm}>
      <StyledForm onSubmit={categoryForm.handleSubmit(handleSubmit)}>
        <Box
          sx={{
            opacity: 1,
            height: '100%',
            // flexDirection: 'column',
            // display: 'flex',
            // justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: '750px', p: 3 }}>
            {/* {imageURLs ? (
              <PreviewImage imageUrl={imageURLs} handleDelete={handleDelete} />
            ) : (
              <>
                <Typography>Cover photo</Typography>
                <CategoryImageUpload handleDropImage={handleDropImage} />
              </>
            )} */}
            <CustomTextInput size="small" name="name" label="Category name" />
            <CustomTextInput size="small" name="description" label="Description" multiline={true} rows={3} />
            <CustomTextInput size="small" name="status" label="Status" select={true}>
              <MenuItem value={'Active'} key={'Active'}>
                Active
              </MenuItem>
              <MenuItem value={'Disabled'} key={'Disabled'}>
                Disabled
              </MenuItem>
            </CustomTextInput>
            <CustomTextInput size="small" name="menuId" label="Choose Menu" select={true}>
              {menuList?.length &&
                menuList.map((menu, index) => {
                  return (
                    <MenuItem value={menu?.id} key={`${menu?.id}-${index}`} selected={true}>
                      {menu?.name}
                    </MenuItem>
                  );
                })}
            </CustomTextInput>
            <StyledFooter>
              {categoryId && (
                <Button sx={{ mr: 5, width: '375px' }} variant={'contained'} color="error" onClick={handleDelete}>
                  Delete
                </Button>
              )}
              <LoadingButton
                disabled={!isFormDirty || isCreateLoading || isUpdateLoading}
                isLoading={isCreateLoading || isUpdateLoading}
                sx={{ width: '375px' }}
                type={'submit'}
                variant={'contained'}
              >
                {!categoryId ? ' Save' : 'Update'}
              </LoadingButton>
              <ConfirmableModal
                open={openConfirmModal}
                onClose={handleCloseConfirmModal}
                onConfirm={handleConfirm}
                title={'Delete'}
                message={'Are you sure you want to delete this item?'}
              />
            </StyledFooter>
          </Box>
        </Box>
      </StyledForm>
    </FormProvider>
  );
};

export default CategoriesForm;
