import React from 'react';
import {
  PopoverActionContent,
  PopoverContentDeleteWrapper,
  PopoverContentEditWrapper,
  PopoverContentWrapper,
} from 'components/UI/PopoverContent/styles';

export interface IMenuPopoverContentPageProps {
  editAction: string;
  deleteAction: string;
  handleEdit: () => void;
  handleDelete: () => void;
}

const PopoverContent = ({ editAction, deleteAction, handleEdit, handleDelete }: IMenuPopoverContentPageProps) => {
  return (
    <PopoverContentWrapper>
      <PopoverContentEditWrapper onClick={handleEdit}>
        <PopoverActionContent>{editAction}</PopoverActionContent>
      </PopoverContentEditWrapper>
      <PopoverContentDeleteWrapper onClick={handleDelete}>
        <PopoverActionContent>{deleteAction}</PopoverActionContent>
      </PopoverContentDeleteWrapper>
    </PopoverContentWrapper>
  );
};

export default PopoverContent;
