import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Box, Drawer, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { isMobile } from 'react-device-detect';
// import { isIOS } from 'react-device-detect';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const drawerWidth = 258;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  // height: '100vh',
  paddingTop: theme.spacing(9),
  width: '100vw',
  // [theme.breakpoints.down('sm')]: {
  //   height: 'calc(100vh - 80px)',
  // },
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: '#fff',
  color: '#202934',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '& .MuiToolbar-root': {
    minHeight: '72px',
  },
}));

export const DrawerWrap = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: '#3c4350',
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiDrawer-paper': {
      width: '100%',
      boxSizing: 'border-box',
      background: '#3c4350',
    },
  },
}));

export const DrawerHeaderWrap = styled(DrawerHeader)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  marginBottom: 16,
  marginTop: 16,
  cursor: 'pointer',
}));

export const ListWrap = styled(List)(({ theme }) => ({
  color: '#fff',
  padding: 0,
}));

export const ListItemWrap = styled(ListItem)(({}) => ({
  // marginBottom: 8,
  cursor: 'pointer',
  ':hover': { background: '#313c48' },
}));

export const HeaderMain = styled(Box)(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const HeaderBack = styled(Box)(({}) => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
}));
