import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    mode: 'light',

    primary: {
      main: '#2D7FF9',
    },

    secondary: {
      main: '#1976d2',
    },
    warning: {
      main: '#ed6c02',
    },
    // success: {

    // },
    text: {
      primary: '#2c405a',
      secondary: '#657786',
    },
    actions: {},
    background: {
      default: '#F2F2F2',
      paper: '#ffffff',
    },
  },

  // Override custom
  components: {
    // this works
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       height: '48px !important',
    //     },
    //     sizeSmall: {
    //       height: '48px !important',
    //     },
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          // background: 'white',
          '& .MuiInputBase-root': {
            background: 'white',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '48px !important',
          fontSize: '1rem',
          // borderRadius: '24px',
          textTransform: 'none',
          fontWeight: 400,
          boxShadow: 'none',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          '&:focus': { outline: 'none' },
        },
      },
    },
    AppBar: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
        },
      },
    },
  },
});

export default theme;
