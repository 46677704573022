import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthBox, AuthSubTitle, AuthTitle, StyledForm, StyledFormBox } from './styles';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import GoBack from './GoBack';
import { IEnterPassword } from './types';
import { useSignInMutation } from 'api';
import LoadingButton from 'components/UI/LoadingButton';

const schemaEnterEmail = yup
  .object()
  .shape({
    email: yup.string().required('This field is required'),
    password: yup.string().required('This field is required'),
  })
  .required();

const EnterPassword = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [signIn, { isLoading }] = useSignInMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const defaultValuesEmail: IEnterPassword = {
    email: state?.email ?? '',
    password: '',
  };

  const enterEmailFormMethods = useForm({
    mode: 'all',
    defaultValues: defaultValuesEmail,
    resolver: yupResolver(schemaEnterEmail),
  });

  const { handleSubmit, reset } = enterEmailFormMethods;

  const onSubmit = async (data: IEnterPassword) => {
    try {
      await signIn(data).unwrap();
      navigate('/overview', { state: {} });
    } catch (err) {}
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  const visiblePassword = () => setShowPassword((prevState) => !prevState);

  return (
    <Box>
      <AuthBox>
        <GoBack />
        <AuthTitle variant="h1">Enter your password</AuthTitle>
        <AuthSubTitle>Please enter your password for sign in.</AuthSubTitle>
        <StyledFormBox>
          <FormProvider {...enterEmailFormMethods}>
            <StyledForm onSubmit={handleSubmit(onSubmit, onError)} noValidate autoComplete="off">
              <Box>
                <CustomTextInput
                  name="password"
                  label="Password"
                  sx={{ mt: 5, width: '100%' }}
                  inputProps={{
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  InputProps={{
                    type: showPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTextInput name="email" sx={{ display: 'none' }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to="/auth/passworResetdEmail"
                    state={{ email: state?.email }}
                  >
                    <Typography sx={{ fontSize: '16px', color: '#007DFF' }} fontWeight={'bold'}>
                      Reset password
                    </Typography>
                  </Link>
                </Box>
              </Box>
              <LoadingButton isLoading={isLoading} type="submit" sx={{ width: '100%' }} variant="contained">
                Sign In
              </LoadingButton>
            </StyledForm>
          </FormProvider>
        </StyledFormBox>
      </AuthBox>
    </Box>
  );
};

export default EnterPassword;
