import React, { useState } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import GoBack from './GoBack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthBox, AuthTitle, StyledForm, StyledFormBox } from './styles';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazySignUpQuery } from 'api';
import { ICreatePassword } from './types';
import FieldFeedback from 'components/FieldFeedback/index';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from 'components/UI/LoadingButton';

const schemaCreatePassword = yup
  .object()
  .shape({
    password: yup
      .string()
      .required('The field is required')
      .min(8, `Password must be at least 8 characters long`)
      .matches(/[a-z]/g, 'Include uppercase and lowercase letters (A, z)')
      .matches(/[A-Z]/g, 'Include uppercase and lowercase letters (A, z)')
      .matches(/[0-9]/g, 'Include Numeric characters (0-9)'),
    confirmPass: yup
      .string()
      .required('The field is required')
      .oneOf([yup.ref('password')], 'Passwords must match'),
    email: yup.string().required('This field is required'),
  })
  .required();

const CreatePassword: any = () => {
  const { state } = useLocation();
  const [signUp, { isLoading }] = useLazySignUpQuery();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const defaultValuesCreatePassword: ICreatePassword = {
    password: '',
    confirmPass: '',
    email: state?.email ?? '',
  };

  const createPasswordFormMethods = useForm({
    mode: 'all',
    defaultValues: defaultValuesCreatePassword,
    resolver: yupResolver(schemaCreatePassword),
  });

  const { handleSubmit, getValues } = createPasswordFormMethods;
  const formData = getValues();

  const onSubmit = async (data: ICreatePassword) => {
    const reqData = {
      password: data.password,
      confirmPass: data.confirmPass,
      email: data.email,
    };
    try {
      await signUp(reqData as SignUpDTO).unwrap();
      navigate('/overview');
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  const visiblePassword = () => setShowPassword((prevState) => !prevState);
  const visibleConfirmPassword = () => setShowConfirmPassword((prevState) => !prevState);

  return (
    <Box>
      <AuthBox>
        <GoBack />
        <AuthTitle variant="h1">Create a Password</AuthTitle>
        <StyledFormBox>
          <FormProvider {...createPasswordFormMethods}>
            <StyledForm onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
              <Box>
                <CustomTextInput
                  name="password"
                  label="Password"
                  sx={{ mt: 5, width: '100%' }}
                  InputProps={{
                    type: showPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTextInput
                  name="confirmPass"
                  label="Confirm password"
                  sx={{ mt: 3, width: '100%' }}
                  InputProps={{
                    type: showConfirmPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={visibleConfirmPassword} edge="end">
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomTextInput name="email" label="Enter email" sx={{ display: 'none' }} />
                <Box sx={{ mt: 2.5 }}>
                  <FieldFeedback
                    value={formData.password}
                    when={(value: string) => new String(value).length >= 8}
                    message="At least 8 characters long"
                  />
                  <FieldFeedback
                    value={formData.password}
                    when={(value: string) => {
                      return /[a-z]/.test(value) && /[A-Z]/.test(value);
                    }}
                    message="Include uppercase and lowercase letters (A,z)"
                  />
                  <FieldFeedback
                    value={formData.password}
                    when={(value: string) => {
                      return /[0-9]/g.test(value);
                    }}
                    message="Include number (3)"
                  />
                  <FieldFeedback
                    value={formData.confirmPass}
                    when={(value: string) => value && value === formData.password}
                    message="Passwords must match"
                  />
                </Box>
              </Box>
              <LoadingButton
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                sx={{ width: '100%' }}
                variant="contained"
              >
                Sign Up
              </LoadingButton>
            </StyledForm>
          </FormProvider>
        </StyledFormBox>
      </AuthBox>
    </Box>
  );
};

export default CreatePassword;
