import { Box, styled } from '@mui/material';

export const StyledFooter = styled(Box)(({ theme }) => ({
  // width: '100%',
  // background: 'white',
  // boxShadow: '0px 0px 6px #B9B9B929',
  marginTop: `${theme.spacing(3)}`,
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
}));
