import {Paper, Box, ListItemText, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuPaper = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
  boxShadow: 'none',
  borderRadius: 0,
})) as typeof Paper;

export const MenuPaperContainer = styled(Box)(({}) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  // cursor: 'pointer',
})) as typeof Box;

export const MenuPaperTexts = styled(Box)(({}) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
})) as typeof Box;

export const MenuPaperListContent = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'column',
})) as typeof Box;

export const MenuPaperListItemTitle = styled(ListItemText)(({}) => ({
  '> span': {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
  },
})) as typeof ListItemText;

export const MenuPaperListItemDescription = styled(ListItemText)(({}) => ({
  '> span': {
    fontSize: '16px',
    fontWeight: 400,
    color: '#8E8E93',
  },
})) as typeof ListItemText;

export const CategoryPaperListWrapper = styled(Box)(({}) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },
})) as typeof Box;

export const CategoryPaperListCountWrapper = styled(Box)(({}) => ({
  display: 'flex',
  alignItems: 'center',
})) as typeof Box;

export const ProductTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#3C3C43',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  }
})) as typeof Typography;
