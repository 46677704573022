import { memo, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Button, DialogContent, DialogActions } from '@mui/material';
import errorImage from 'assets/svg/error.svg';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { CustomDialogWrapper } from './styles';
import { clearError } from 'features/error';

const ServerErrorModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { errorMessage, showError, errorTitle } = useAppSelector((state) => state.error);
  const disaptch = useAppDispatch();

  useEffect(() => {
    setIsOpen(Boolean(errorMessage && showError));
  }, [errorMessage, showError]);

  const handleClose = () => {
    disaptch(clearError());
  };

  return (
    <>
      <CustomDialogWrapper
        open={isOpen}
        onClose={handleClose}
        title={''}
        sx={{
          '& .MuiDialog-root': {
            zIndex: 99999,
          },
          // [theme.breakpoints.up("md")]: {
          '& .MuiPaper-root': {
            minWidth: '372px !important',
            width: '372px',
            height: 'auto',
          },
          // },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyCOntent: 'center',
              flexDirection: 'column',
              mt: 3,
              mb: 3,
            }}
          >
            <img src={errorImage} alt="Error" />
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', my: 2 }}>{errorTitle || ''}</Typography>
            <Typography sx={{ fontSize: '16px' }}>{errorMessage}</Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 3,
          }}
        >
          <Button
            onClick={handleClose}
            type="submit"
            variant="contained"
            sx={{
              background: '#E9E9E9',
              color: '#000000',
              width: '247px',
              height: '45px',
              borderRadius: '45px',
              '&:hover': {
                color: 'white',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </CustomDialogWrapper>
    </>
  );
};
export default memo(ServerErrorModal);
