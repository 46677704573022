import { useGetItemQuery } from 'api';
import CreateItem from 'components/MenuItem/CreateItem';
import EditItem from 'components/MenuItem/EditItem';
// import { WelcomePage } from 'components/UI/WelcomePage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ItemsList from './ItemsList';
import { useAppSelector } from 'hooks/useReduxHook';
import { menuDataSelectors } from 'features/menuData';
import { useEffect } from 'react';

const MenuItems = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const categoryList = useAppSelector(menuDataSelectors.allCategories);
  const menuDataIsLoading = useAppSelector(menuDataSelectors.menuDataIsLoading);

  const [searchParams] = useSearchParams();
  const itemId = searchParams.get('id') || '';
  const { data: currentItem, isFetching: isGetItemLoading } = useGetItemQuery(itemId, { skip: !itemId });

  useEffect(() => {
    if (!categoryList.length && !menuDataIsLoading) {
      navigate('/categories');
    }
  }, [categoryList, menuDataIsLoading]);

  if (location.pathname.includes('/items/create')) {
    return <CreateItem />;
  }
  if (itemId) {
    <EditItem />;
  }

  return <ItemsList />;
};

export default MenuItems;
