import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'features';
import { api } from 'api';

type CategoriesState = {
  selectedMenuId: string;
};

// Define the initial state using that type
const initialState: CategoriesState = {
  selectedMenuId: '',
};

export const categoriesSlice = createSlice({
  name: 'categories',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedMenuId(state, action) {
      state.selectedMenuId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getMenuList.matchFulfilled, (state, { payload }) => {
      if (!payload || !payload.length) return;

      // If currently selected menu was deleted - choose the first from the list
      if (!payload.find((menu) => state.selectedMenuId === menu.id)) {
        state.selectedMenuId = payload[0].id;
      }
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const categoriesSelectors = {
  selectedMenuId: (state: RootState) => state.categories.selectedMenuId,
};

export default categoriesSlice;
