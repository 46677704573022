import {Box, Grid, Typography} from "@mui/material";
import CategoriesList from "pages/Overview/Menu/Categories/CategoriesList";
import {useChangeCategorySortingMutation} from "api";
import {arrayMove} from "react-sortable-hoc";
import {useEffect, useState} from "react";


const OverviewWrapper = ({list, name, menuCount}: any) => {
  const [updateSorting, { isLoading: isSortingLoading }] = useChangeCategorySortingMutation();
  const [listCategories, setListCategories] = useState([]);

  useEffect(() => {
    if(list && list?.categories?.length) {
      setListCategories(list.categories);
    }
  },[list])


  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex === newIndex) {
      return;
    }
    let sortedData = arrayMove(listCategories, oldIndex, newIndex);
    setListCategories(sortedData);
    const newCategoryOrder: SortingDTO[] = sortedData.map((category: any, index) => ({
      id: category?.id,
      sort: index,
    }));
    updateSorting(newCategoryOrder);
  };
  return (
    <Grid container spacing={1}>
      {menuCount > 1 && (
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>{name}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box>
          {listCategories?.length ? (
            <CategoriesList isSortingLoading={isSortingLoading} onSortEnd={onSortEnd} categories={listCategories} />
          ) : (
            <Typography sx={{ textAlign: 'center', mt: 3 }}>
              {'No categories created for this menu yet'}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
export default OverviewWrapper;