import { memo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import WelcomeImage from 'assets/img/welcome/welcome.png';
import {
  StyledWelcomeButton,
  StyledWelcomeContainer,
  StyledWelcomeDescription,
  StyledWelcomeTitle,
  StyledWelcomeWrapper,
} from './styles';
import { useNavigate } from 'react-router-dom';
export interface IWelcomePageProps {
  title: string;
  description: string;
  url: string;
  buttonText?: string | undefined;
}
export const WelcomePage = memo(({ title, description, url, buttonText = 'Add new' }: IWelcomePageProps) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(url);
  };

  return (
    <StyledWelcomeWrapper>
      <StyledWelcomeContainer>
        <img src={WelcomeImage} alt="welcome" />
        <StyledWelcomeTitle>{title}</StyledWelcomeTitle>
        <StyledWelcomeDescription>{description}</StyledWelcomeDescription>
        <StyledWelcomeButton variant="contained" startIcon={<AddIcon />} onClick={handleNavigate}>
          {buttonText}
        </StyledWelcomeButton>
      </StyledWelcomeContainer>
    </StyledWelcomeWrapper>
  );
});
