import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useReduxHook';
import {menuDataSelectors} from 'features/menuData';
import { businessSelectors } from 'features/business';
import {StyledBox} from 'components/newOverview/styles';
import Preloader from 'components/UI/Preloader/Preloader';
import {useLazyGetCategoriesWithItemsQuery} from "api";
import OverviewWelcome from 'components/Overview/OverviewWelcome';
import OverviewWrapper from 'components/newOverview/OverviewWrapper';

const Overview = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [getAllMenuWithCategoryItems] = useLazyGetAllMenuWithCategoryItemsQuery();
  const [getCategoriesWithItems] = useLazyGetCategoriesWithItemsQuery();
  const businessId = useAppSelector(businessSelectors.business)?.id;
  const businessLoading = useAppSelector(businessSelectors.businessLoading);
  const navigate = useNavigate();
  const menuList = useAppSelector(menuDataSelectors.menus);
  const isLoading = useAppSelector(menuDataSelectors.menuDataIsLoading);

  useEffect(() => {
    if (!businessId && !businessLoading) {
      navigate('/business-profile');
    }
  }, [businessId]);

  const convertMenuWithCategoryAndItems = (catList, menuList) => {
    if(catList && catList?.length && menuList && menuList.length) {
      const menuCategoryMap = {};
      catList.forEach((category) => {
        const { menuId, name } = category;

        if (!menuCategoryMap[menuId]) {
          menuCategoryMap[menuId] = [];
        }

        menuCategoryMap[menuId].push(category);
      });

      const updatedMenusWithCategories = menuList.map((menu) => ({
        ...menu,
        categories: menuCategoryMap[menu.id] || [],
      }));
      return updatedMenusWithCategories || []
    }
  }


  useEffect(() => {
    if(menuList && menuList?.length) {
      setLoading(true);
      const promises = [];
      menuList.forEach((menuItem) => {
        promises.push(getCategoriesWithItems(menuItem?.id));
      });
      Promise.all(promises)
        .then((res: any) => {
          const listCategories = [];
          res.forEach((resItem) => {
            listCategories.push(...resItem.data);
          });
          let data = convertMenuWithCategoryAndItems(listCategories, menuList);
          setList(data);
          setLoading(false);
        }).catch(() => setLoading(false))
    }
  },[menuList])



  if (isLoading || loading || !menuList) {
    return <Preloader overlay sx={{ margin: 0 }} />;
  }

  if (!menuList?.length && menuList) {
    return <OverviewWelcome />;
  }
  return (
    <Grid container>
      <Grid item md={12} xs={12} sm={12} lg={12}>
       {!loading && list && list?.length ? (
         list.map((item) => {
           return (
             <StyledBox key={item?.id}>
               <OverviewWrapper list={item} name={item?.name} menuCount={list?.length}/>
             </StyledBox>
           )
        })
        ) : ("")}
      </Grid>
    </Grid>
  );
};

export default Overview;
