import { Box, Dialog, DialogContent, Typography, styled } from '@mui/material';

export const CustomDialogWrapper = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container': {
    '.MuiPaper-root': {
      '.MuiDialogContent-root': {
        padding: theme.spacing(2, 2),
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    '.MuiDialog-container': {
      '.MuiPaper-root': {
        minWidth: '502px',
      },
    },
  },
}));
export const CustomDeliveryWrapper = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container': {
    marginTop: theme.spacing(5),
    alignItems: 'baseline',
    minHeight: '500px',
    '.MuiPaper-root': {
      width: '100%',

      maxWidth: '640px',
      '.MuiDialogContent-root': {
        padding: theme.spacing(2, 2),
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    '.MuiDialog-container': {
      '.MuiPaper-root': {
        width: '100%',
        maxWidth: '640px',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '.MuiDialog-container': {
      marginTop: theme.spacing(0),
    },
  },
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));
export const BoxIcon = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
export const TypographyCocial = styled(Typography)({
  fontSize: '14px',
  marginTop: '16px',
});
