import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks/useReduxHook';
import { WelcomePage } from 'components/UI/WelcomePage';
import { businessSelectors } from 'features/business';
import Preloader from 'components/UI/Preloader/Preloader';
import CategoryList from 'components/Categories/index';
import { useLazyGetMenuListQuery } from 'api/index';
import { menuDataSelectors } from 'features/menuData';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
  const businessId = useAppSelector(businessSelectors.business)?.id;
  const categoryList = useAppSelector(menuDataSelectors.allCategories);
  const menus = useAppSelector(menuDataSelectors.menus);
  const loading = useAppSelector(menuDataSelectors.menuDataIsLoading);
  const [getMenus] = useLazyGetMenuListQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (businessId) {
      getMenus(businessId);
    }
  }, [businessId]);

  if (menus && !menus.length) {
    navigate('/menus');
  }

  if (loading) {
    return <Preloader overlay={true} sx={{ margin: 0 }} />;
  }

  if (!categoryList?.length && businessId && !loading) {
    return (
      <WelcomePage
        description={'Click on the PLUS icon below to create your first category.'}
        url={'/categories/create'}
        title={'Categories will appear here'}
      />
    );
  }

  return <>{categoryList.length ? <CategoryList categories={categoryList} /> : []}</>;
};

export default Categories;
