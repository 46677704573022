import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Box, IconButton } from '@mui/material';

export interface PreviewImagePageProps {
  imageUrl: string;
  handleDelete: () => void;
}
const PreviewImage = ({ imageUrl, handleDelete }: PreviewImagePageProps) => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          maxWidth: '180px',
        }}
      >
        <Avatar variant="square" alt="img" src={imageUrl} sx={{ width: '180px', height: '180px', mb: 2 }} />
        <IconButton
          aria-label="delete"
          onClick={handleDelete}
          sx={{
            background: 'red',
            color: 'white',
            padding: '5px',
            position: 'absolute',
            borderRadius: '5px',
            top: '10px',
            right: '10px',
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default PreviewImage;
