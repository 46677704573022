import React, { ReactNode, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  ListItemText,
  ListItemButton,
  CssBaseline,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import Help from 'pages/Help';
// import Menus from 'pages/Menus';
import NotFound from 'pages/NotFound';
import Overview from 'pages/Overview';
import authSlice from 'features/auth';
import { MENU_LIST } from './menuList';
import MyAccount from 'pages/MyAccount';
// import MenuItems from 'pages/MenuItems';
import Categories from 'pages/Categories';
import QrIcon from 'assets/svg/qrIcon.svg';
import { configSelectors } from 'features/config';
import MenuIcon from '@mui/icons-material/Menu';
import navigationSlice from 'features/navigation';
// import HeaderLogo from 'assets/svg/headerLogo.svg';
import HeaderLogo from 'assets/img/logo/orders_LogoAPP.png';

import BusinessProfile from 'pages/BusinessProfile';
import WebsiteSettings from 'pages/WebsiteSettings';
// import MenuForm from 'components/Menu/MenuForm/MenuForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConfirmableModal from 'components/Modals/ConfirmableModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import CategoriesForm from 'components/Categories/CategoriesForm/CategoriesForm';
import { AppBar, DrawerHeaderWrap, DrawerWrap, HeaderBack, HeaderMain, ListItemWrap, ListWrap, Main } from './styles';
import configSlice from '../../features/config';
import CreateItem from 'components/MenuItem/CreateItem';
import EditItem from 'components/MenuItem/EditItem';
import ItemsList from 'pages/MenuItems/index';
import { useSidebarConfig } from 'hooks/useSidebarConfig';
import { useMatchTablet } from 'hooks/useMatchMobile';

export interface IMenuListItemProps {
  name: string;
  path: string;
  show: boolean;
  backIcon: boolean;
  breadcrumbName: string;
  breadcrumbPath: string;
  MenuIcon: ReactNode | any;
}

const SidebarDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMatchTablet } = useMatchTablet();
  const haveFormChange = useAppSelector(configSelectors.haveFormChange);
  const dispatch = useAppDispatch();
  const { title, backIcon } = useAppSelector((state: { navigation: any }) => state.navigation);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [open, setOpen] = React.useState(true);
  const [logout, setLogout] = useState<boolean>(false);
  const { sidebarConfig } = useSidebarConfig();
  const [currentRedirectRoute, setCurrentRedirectRoute] = useState<{ title: string; path: string }>({
    title: '',
    path: '',
  });
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleClickLogo = () => {
    navigate('overview');
  };

  useEffect(() => {
    if (isMatchTablet) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isMatchTablet]);
  useEffect(() => {
    const fondData = MENU_LIST.find((item) => location.pathname === item.breadcrumbPath);
    if (fondData) {
      dispatch(
        navigationSlice.actions.setNavigation({
          title: fondData.name,
          path: fondData.path,
          backIcon: fondData.backIcon,
        }),
      );
    }
  }, [location]);

  const handleClick = () => {
    if (haveFormChange) {
      setOpenConfirmationModal(true);
      setCurrentRedirectRoute({ title: '', path: '' });
      return;
    }
    navigate(-1);
  };
  const handleNavigate = (item: IMenuListItemProps) => {
    if (isMatchTablet) {
      setOpen(false);
    }
    let lastMenuElement = MENU_LIST[MENU_LIST.length - 1];
    if (item.breadcrumbName === lastMenuElement.breadcrumbName && haveFormChange) {
      setOpenConfirmationModal(true);
      setLogout(true);
    } else if (item.breadcrumbName === lastMenuElement.breadcrumbName && !haveFormChange) {
      dispatch(authSlice.actions.logout());
      setLogout(false);
      setOpenConfirmationModal(false);
      return;
    }
    if (haveFormChange) {
      setOpenConfirmationModal(true);
      setCurrentRedirectRoute({ title: item.name, path: item.path });
    } else {
      setOpenConfirmationModal(false);
      setCurrentRedirectRoute({ title: '', path: '' });
      dispatch(navigationSlice.actions.setNavigation({ title: item.name, path: item.path }));
      navigate(item.path);
    }
  };
  const handleClickNavigate = () => navigate('/website-settings');

  const handleCloseModal = () => {
    if (logout) {
      dispatch(configSlice.actions.formChangeWatcher(false));
      setLogout(false);
      setOpenConfirmationModal(false);
      dispatch(authSlice.actions.logout());

      return;
    }
    if (currentRedirectRoute?.path && currentRedirectRoute.title) {
      dispatch(navigationSlice.actions.setNavigation(currentRedirectRoute));
      setCurrentRedirectRoute({ title: '', path: '' });
      dispatch(configSlice.actions.formChangeWatcher(false));
      setOpenConfirmationModal(false);
      navigate(currentRedirectRoute?.path);
    } else {
      setOpenConfirmationModal(false);
      dispatch(configSlice.actions.formChangeWatcher(false));
      navigate(-1);
    }
  };
  const handleConfirm = () => {
    setCurrentRedirectRoute({ title: '', path: '' });
    setOpenConfirmationModal(false);
  };

  // if (location.pathname === '/404') {
  //   return <NotFound />;
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [open])

  const isInerrorPage = location.pathname === '/404';

  return (
    <Box sx={{ display: 'flex' }}>
      {!isInerrorPage && (
        <>
          <CssBaseline />
          <AppBar open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <HeaderMain>
                <HeaderBack>
                  {backIcon && (
                    <IconButton onClick={handleClick}>
                      <Box sx={{ cursor: 'pointer' }}>
                        <ArrowBackIcon />
                      </Box>
                    </IconButton>
                  )}
                  <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 700 }}>
                    {title}
                  </Typography>
                </HeaderBack>
                <Box component="img" src={QrIcon} alt="Qr" sx={{ cursor: 'pointer' }} onClick={handleClickNavigate} />
              </HeaderMain>
            </Toolbar>
          </AppBar>
          <DrawerWrap variant="persistent" anchor="left" open={open}>
            <DrawerHeaderWrap onClick={handleClickLogo}>
              <Box component="img" src={HeaderLogo} alt="Orders.co" width={48} height={48} />
              <Typography ml={2} color="white" fontSize={16} fontWeight={500}>
                QR menu maker
              </Typography>
            </DrawerHeaderWrap>
            <Divider />
            <ListWrap>
              {MENU_LIST.map((item) => {
                if (item?.show) {
                  const isDisabled = !sidebarConfig[item.isActiveKey];
                  return (
                    <ListItemWrap
                      className={location.pathname.includes(item.path) ? 'active' : ''}
                      sx={{
                        ':hover': {
                          background: isDisabled ? 'none' : '#313c48',
                        },
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                      }}
                      key={item.name}
                      disablePadding
                    >
                      <ListItemButton
                        disabled={isDisabled}
                        onClick={() => {
                          !isDisabled ? handleNavigate(item) : '';
                        }}
                      >
                        <ListItemIcon sx={{ color: '#fff' }}>{item.MenuIcon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItemWrap>
                  );
                }
              })}
            </ListWrap>
            <Divider />
          </DrawerWrap>
        </>
      )}

      <Main open={open} style={{ width: '100vw', marginLeft: isInerrorPage ? 0 : '' }}>
        <ConfirmableModal
          open={openConfirmationModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
          title="Unsaved changes"
          message="Do you want to leave before saving the changes?"
          cancelButton={'Leave'}
          confirmButton={'Stay on The Form'}
        />

        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/overview" element={<Overview />} />
          {/*<Route path="/menus" element={<Menus />} />*/}
          {/*<Route path="/menus/create" element={<MenuForm />} />*/}
          {/*<Route path="/menus/edit" element={<MenuForm />} />*/}
          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/create" element={<CategoriesForm />} />
          <Route path="/categories/edit" element={<CategoriesForm />} />
          <Route path="/items" element={<ItemsList />} />
          <Route path="/items/create" element={<CreateItem />} />
          <Route path="/items/edit" element={<EditItem />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/business-profile" element={<BusinessProfile />} />
          <Route path="/website-settings" element={<WebsiteSettings />} />
          <Route path="/help" element={<Help />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
    </Box>
  );
};

export default SidebarDrawer;
