import { Box, DialogContent, IconButton, Typography } from '@mui/material';
import { CustomDialogWrapper } from './styles';
import IconWhatsApp from 'assets/svg/whatsapp/WhatsApp.svg';
import SaveIcon from 'assets/img/save/save.png';
import IconTelegram from 'assets/svg/telegram/Telegram.svg';
import IconFacebook from 'assets/svg/facebook/Facebook.svg';
import IconTwitter from 'assets/svg/twitter/Twitter.svg';
import IconEmail from 'assets/svg/email/E-mail.svg';
import CloseIcon from '@mui/icons-material/Close';
import useCopyToClipboard from 'hooks/useCopy';
import { useGetQRQuery } from 'api';
import { useAppSelector } from 'hooks/useReduxHook';
import { businessSelectors } from 'features/business';
import {
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import { useMatchMobile } from 'hooks/useMatchMobile';

interface ShareModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ShareModal = ({ isOpen, setIsOpen }: ShareModalProps) => {
  const [copy] = useCopyToClipboard();
  const business = useAppSelector(businessSelectors.business);
  const { data: qrData, isFetching: isLoading } = useGetQRQuery(business?.id ?? '', { skip: !business?.id });
  const { isMatchMobile } = useMatchMobile();

  const handleClick = () => {
    setIsOpen(false);
  };

  const dataToCopy: string = qrData?.link || '';
  const handleCopyClick = () => {
    copy(dataToCopy);
  };

  const params = { url: qrData?.link || '', quote: qrData?.base64Pdf };

  const social = [
    {
      iconButton: () => (
        <WhatsappShareButton {...params}>
          <Box sx={{ width: isMatchMobile ? '62px' : '' }}>
            <img src={IconWhatsApp} style={{ height: isMatchMobile ? '50px' : '' }} />
            <Typography sx={{ mt: 1, fontSize: isMatchMobile ? '14px' : '' }}>Whatsapp</Typography>
          </Box>
        </WhatsappShareButton>
      ),
      label: 'WhatsApp',
      socialWork: 'https://www.whatsapp.com/',
    },
    {
      iconButton: () => (
        <TelegramShareButton {...params}>
          <Box sx={{ width: isMatchMobile ? '62px' : '' }}>
            <img src={IconTelegram} style={{ height: isMatchMobile ? '50px' : '' }} />
            <Typography sx={{ mt: 1, fontSize: isMatchMobile ? '14px' : '' }}>Telegram</Typography>
          </Box>
        </TelegramShareButton>
      ),
      label: 'Telegram',
      socialWork: 'https://web.telegram.org/a/',
    },
    {
      iconButton: () => (
        <FacebookShareButton {...params}>
          <Box sx={{ width: isMatchMobile ? '62px' : '' }}>
            <img src={IconFacebook} style={{ height: isMatchMobile ? '50px' : '' }} />
            <Typography sx={{ mt: 1, fontSize: isMatchMobile ? '14px' : '' }}>Facebook</Typography>
          </Box>
        </FacebookShareButton>
      ),
      label: 'Facebook',
      socialWork: 'https://www.facebook.com/ordersco/',
    },

    {
      iconButton: () => (
        <TwitterShareButton {...params}>
          <Box
            sx={{
              width: isMatchMobile ? '62px' : '100%',
            }}
          >
            <img src={IconTwitter} style={{ height: isMatchMobile ? '50px' : '' }} />
            <Typography sx={{ mt: 1, fontSize: isMatchMobile ? '14px' : '' }}>Twitter</Typography>
          </Box>
        </TwitterShareButton>
      ),
      label: 'Twitter',
      socialWork: 'https://twitter.com/i/flow/login?redirect_after_login=%2Ftryordersco',
    },
    // {
    //   iconButton: () => (
    //     <InstapaperShareButton {...params}>
    //       <Box>
    //         <img src={IconInstagram} />
    //         <Typography sx={{ mt: 1 }}>Instagram</Typography>
    //       </Box>
    //     </InstapaperShareButton>
    //   ),
    //   label: 'Instagram',
    //   socialWork: 'https://www.instagram.com/tryordersco/',
    // },
    {
      iconButton: () => (
        <EmailShareButton {...params}>
          <Box sx={{ width: isMatchMobile ? '62px' : '', textAlign: 'center' }}>
            <img src={IconEmail} style={{ height: isMatchMobile ? '50px' : '' }} />
            <Typography sx={{ mt: 1, fontSize: isMatchMobile ? '14px' : '' }}>Email</Typography>
          </Box>
        </EmailShareButton>
      ),
      label: 'Email',
      socialWork: 'Orders.co',
    },
  ];

  return (
    <CustomDialogWrapper
      open={isOpen}
      title={''}
      sx={{
        '& .MuiDialog-root': {
          zIndex: 99999,
        },

        '& .MuiPaper-root': {
          width: '640px',
          height: 'auto',
          borderRadius: '20px',
        },
      }}
    >
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography>Share QR menu link</Typography>
          <IconButton sx={{ p: 0 }} onClick={handleClick}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            mt: 3,
            mb: 3,
          }}
        >
          {social.map((item) => {
            return item.iconButton && item.iconButton();
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ mt: 2 }} fontSize={'14px'}>
            Or copy with link
          </Typography>
        </Box>

        <Box
          sx={{
            px: 2,
            width: '100%',
            height: '53px',
            border: '1px solid #E0E0E0',
            borderRadius: '27px',
            mt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center',

            overflow: 'scroll',
            '&::-webkit-scrollbar': {
              height: '0px',
            },
          }}
        >
          <Typography>{dataToCopy}</Typography>
          <Box sx={{ alignItems: 'baseline', display: 'flex' }}>
            <IconButton aria-label="copy link" edge="end" onClick={handleCopyClick}>
              <img src={SaveIcon} alt="Save Link" />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </CustomDialogWrapper>
  );
};
export default ShareModal;
