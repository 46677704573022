import { Box, styled, Typography, Button } from '@mui/material';

export const StyledWelcomeWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '358px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '318px',
  },
}));

export const StyledWelcomeContainer = styled(Box)(({}) => ({
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
}));

export const StyledWelcomeTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: '20px',
  fontWeight: 600,
}));

export const StyledWelcomeDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: '16px',
  fontWeight: 500,
  maxWidth: '480px',
  textAlign: 'center',
}));

export const StyledWelcomeButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
