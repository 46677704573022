import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './useReduxHook';
import { businessSelectors } from 'features/business';
import { useGetMenuListQuery, useLazyGetCategoryListQuery } from 'api';
import { MENU_LIST } from 'components/SidebarDrawer/menuList';
import { menuDataSelectors, updateAllCategories, updateMenus, updateisLoading } from 'features/menuData';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Get initial data from AsyncStorage or server
 */
export function useSidebarConfig() {
  const dispatch = useAppDispatch();
  const businessId = useAppSelector(businessSelectors.business)?.id;
  const navigate = useNavigate();
  const location = useLocation();
  const { data: menuList, isFetching: isLoadingMenu } = useGetMenuListQuery(businessId as string, {
    skip: !businessId,
  });
  const menuListData = useAppSelector(menuDataSelectors.menus);
  const categories = useAppSelector(menuDataSelectors.allCategories);

  const [getCategoriesList] = useLazyGetCategoryListQuery();
  const initialConfig = {};
  MENU_LIST.forEach((item) => {
    initialConfig[item.isActiveKey] = true;
  });

  const [configData, setConfigData] = useState(initialConfig);

  useEffect(() => {
    dispatch(updateMenus(menuList));
  }, [menuList]);

  useEffect(() => {
    if (menuListData) {
      const promises = [];
      menuListData.forEach((manuItem) => {
        promises.push(getCategoriesList(manuItem.id));
      });
      Promise.all(promises)
        .then((res: any) => {
          const listCategries = [];
          res.forEach((resItem) => {
            listCategries.push(...resItem.data);
          });
          dispatch(updateAllCategories(listCategries));
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(updateisLoading(false));
          }, 500);
        });
    }
  }, [businessId, menuListData]);

  useEffect(() => {
    if (!businessId) {
      setConfigData({
        ...initialConfig,
        overviewIsActive: false,
        categoriesIsActive: false,
        websiteSettingsIsActive: false,
        itemsIsActive: false,
        menusisActive: false,
      });
    } else {
      if (location.pathname === '/') {
        navigate('/overview');
      }
      if (!menuListData?.length) {
        setConfigData({
          ...initialConfig,
          categoriesIsActive: false,
          itemsIsActive: false,
        });
      } else {
        if (!categories?.length) {
          setConfigData({
            ...initialConfig,
            itemsIsActive: false,
          });
        } else {
          setConfigData(initialConfig);
        }
      }
    }
  }, [businessId, menuListData, categories]);

  return { sidebarConfig: configData };
}
