import React from 'react';
import { List } from '@mui/material';
import { SortableContainer } from 'react-sortable-hoc';
import SortableDraggableMenuItem from './SortableDraggbleListItem';
import Preloader from 'components/UI/Preloader/Preloader';

interface ISortableContainerProps {
  list: ItemFullDTO[];
  onSortEnd: (oldIndex: number, newIndex: number) => void;
  isLoading: boolean;
}

const SortableItemList = SortableContainer<ISortableContainerProps>(({ list, isLoading }: ISortableContainerProps) => {
  return (
    <List>
      {isLoading && <Preloader overlay={true} sx={{ margin: 0 }} />}
      {list.map((value: any, index: any) => (
        <SortableDraggableMenuItem
          isLoading={isLoading}
          key={index}
          index={index}
          item={value}
          status={value?.status}
          showBorder={index < list.length - 1}
        />
      ))}
    </List>
  );
});

export default SortableItemList;
