import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import authSlice, { authSelectors } from 'features/auth';
import { useAppSelector } from 'hooks/useReduxHook';
import { useDeleteProfileMutation } from 'api';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ConfirmableModal from 'components/Modals/ConfirmableModal';
import { businessSelectors } from 'features/business';
import {MyAccountBox, MyAccountContentBox} from "./styles";

const MyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useAppSelector(authSelectors.user)?.email ?? '';
  const businessId = useAppSelector(businessSelectors.business)?.id;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteProfile] = useDeleteProfileMutation();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleOpenModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirm = async () => {
    setOpenConfirmModal(false);
    try {
      await deleteProfile(undefined).unwrap();
      setIsDeleteModalVisible(false);
      dispatch(authSlice.actions.logout());

      // setTimeout(() => {
      // }, 600);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveAccount = () => {
    handleOpenModal();
  };

  return (
    <Grid container>
      <Grid item md={5} sm={4}>
        <MyAccountBox>
          <Divider />
          <MyAccountContentBox>
            <Typography>Email</Typography>
            <Box sx={{ mx: 2 }}>{email}</Box>
            <Box></Box>
          </MyAccountContentBox>
          <Divider />
          <MyAccountContentBox>
            <Typography>Password</Typography>
            <Typography sx={{ mx: 2 }}>*****************************</Typography>
            <Box>
              <Link state={{ email: email }} to="/changePassword" style={{ color: '#007DFF' }}>
                Change
              </Link>
            </Box>
          </MyAccountContentBox>
          <Divider />
          <Box sx={{ mt: 4, cursor: businessId ? 'pointer' : 'not-allowed', opacity: !businessId ? 0.5 : 1 }}>
            <Typography
              color="error"
              onClick={businessId ? handleRemoveAccount : () => {}}
              sx={{ textDecoration: 'underline' }}
            >
              Remove account
            </Typography>
            <Typography>If you remove account, you will lose your data.</Typography>
          </Box>
        </MyAccountBox>
      </Grid>
      <ConfirmableModal
        open={openConfirmModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        title="Remove account"
        message="Are you sure you want to remove your account? You will lose your data permanently."
      />
    </Grid>
  );
};

export default MyAccount;
