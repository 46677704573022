import * as yup from 'yup';

const REQUIRED_MESSAGE = 'The field is required';
export const categorySchema = yup.object({
  name: yup.string().required(REQUIRED_MESSAGE),
  description: yup.string()
    .optional(),
  pictures: yup.object().nullable().optional(),
  menuId: yup.string()
    .required(REQUIRED_MESSAGE),
  status: yup
    .string().oneOf(['Active', 'Disabled'])
    .required(REQUIRED_MESSAGE),
});