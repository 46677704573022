import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthInit from 'pages/Authenticate/index';
import Authenticate from 'pages/Authenticate/Authenticate';
import NotFound from './NotFound';

function Auth() {
  return (
    <Routes>
      <Route path="/" element={<AuthInit />} />
      <Route path="auth/*" element={<Authenticate />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
export default Auth;
