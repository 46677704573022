import React, { useState, useEffect, ChangeEvent } from 'react';
import { IconButton, InputAdornment, Grid, Button, List, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { SearchInput } from 'components/Menu/styles';
import SearchIcon from '@mui/icons-material/Search';
import CategoryListItem from './CategoryListItem';

export interface IPictureCategory {
  fileId: string;
  url: string;
  default: boolean;
}

export interface ICategoriesPageProps {
  name: string;
  description: string;
  id: string;
  items: number;
  menuId: string;
  pictures: null | IPictureCategory;
  sort: number;
  status: boolean;
}

const CategoriesList = ({ categories }: { categories: any }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>('');
  const [categoryItems, setCategoryItems] = useState<ICategoriesPageProps[]>([]);
  console.log('categoryItems: ', categoryItems);

  const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
    let search = event.target.value;
    setValue(search);
    if (search) {
      let filterCategory: any = [];
      categories.forEach((category) => {
        if (category?.name?.toLowerCase().includes(search.trim().toLowerCase())) {
          filterCategory.push(category);
        }
      });
      setCategoryItems(filterCategory);
    } else {
      setCategoryItems(categories);
    }
  };

  const clearValue = () => {
    setValue('');
    setCategoryItems(categories);
  };

  useEffect(() => {
    setCategoryItems(categories);
  }, [categories]);

  return (
    <>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex' }}>
            <SearchInput
              size="small"
              value={value}
              onChange={handleInput}
              label={'Search categories'}
              placeholder={'Search categories...'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {value.length ? (
                      <IconButton size={'small'} onClick={clearValue}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </InputAdornment>
                ),
                sx: {
                  width: '100%',
                  background: 'white',
                  minHeight: '48px',
                },
              }}
            />
            <Button
              sx={{ minWidth: '140px', ml: 2 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/categories/create')}
            >
              Add New
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <List>
            {categoryItems.map((value: any, index: any) => (
              <CategoryListItem
                key={value.id}
                name={value?.name}
                description={value?.description}
                id={value.id}
                countOfItems={value?.items}
                status={value.status}
              />
            ))}
          </List>
          <Typography sx={{ textAlign: 'center' }}>
            {value.length && !categoryItems.length ? 'No categories found for your search.' : ''}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CategoriesList;
