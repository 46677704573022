import Box from '@mui/material/Box';
import { styled } from '@mui/material';
export const PreLoaderContainer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '129px',
  '&.overlay': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '112',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(225, 225, 225, 0.75)',
    // []
  },
}));

export const PreloaderBlock = styled(Box)(() => ({
  marginTop: 0,
  marginBottom: 0,
  textAlign: 'center',
  verticalAlign: 'middle',
  position: 'relative',
  display: 'inline-block',
  margin: 'auto',
}));
