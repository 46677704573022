import {Box, styled, TextField} from '@mui/material';

export const StyledImageWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '180px',
  height: '180px',
  background: '#2D5CF9',
  position: 'relative',
  borderRadius: '4px'
}))

export const StyledFileField = styled(TextField)(({ theme }) => ({
  display: 'none',
  width: '180px',
  height: '180px'
}));


export const StyledInputLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  top: '62px',
  left: '60px',
}));

export const StyledDraggbleWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '220px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  margin: theme.spacing(4, 'auto'),
  border:' 1px dashed black',
  borderRadius: '4px',
  transition: '0.2s box-shadow, 0.2s background-color',
  boxShadow:' 0 10px 40px -30px rgba(black, 0.2)',
  background: theme.palette.background.paper,
}));

export const StyledDraggbleContent = styled(Box)(({ theme }) => ({
 display: 'flex',
 flexDirection: 'column',
 alignItems: 'center'
}));

export const StyledDraggbleTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#657786',
  fontSize: '14px',
  paddingTop: theme.spacing(1),
}));
