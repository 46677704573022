import { createSlice } from '@reduxjs/toolkit';
import { api } from 'api';
import { RootState } from 'features';

type MenuData = {
  menus: MenuFullDTO[];
  allCategories: CategoryFullDTO[];
  allItems: ItemFullDTO[];
  loading: boolean;
};

// Define the initial state using that type
const initialState: MenuData = {
  menus: [],
  allCategories: [],
  allItems: [],
  loading: false,
};

export const menuDataSlice = createSlice({
  name: 'config',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateMenus(state, action) {
      state.menus = action.payload;
    },
    updateAllCategories(state, action) {
      state.allCategories = action.payload;
    },
    updateAllItems(state, action) {
      state.allItems = action.payload;
    },
    updateisLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getMenuList.matchPending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addMatcher(api.endpoints.getMenuList.matchFulfilled, (state, { payload }) => {
      state.menus = payload;
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const menuDataSelectors = {
  menus: (state: RootState | any) => state.menuData.menus,
  allCategories: (state: RootState | any) => state.menuData.allCategories,
  allItems: (state: RootState | any) => state.menuData.allItems,
  menuDataIsLoading: (state: RootState | any) => state.menuData.loading,
};

export const { updateAllCategories, updateAllItems, updateisLoading, updateMenus } = menuDataSlice.actions;

export default menuDataSlice;
