import React from 'react';
import { Box, Button, MenuItem } from '@mui/material';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { StyledFooter } from 'components/UI/LoadingButton/styles';
import { StyledForm } from 'pages/Authenticate/styles';
import { useLazySendFeedbackQuery } from 'api';
import { useAppSelector } from 'hooks/useReduxHook';
import { authSelectors } from 'features/auth';

export interface FormInfoI {
  description: string;
  email: string;
  type: string;
}

const Help = () => {
  const userEmail = useAppSelector(authSelectors.user)?.email;
  // const [TypeName, setTypeName] = React.useState();

  // const handleChange = (event: any) => {
  //   setTypeName(event.target.value);
  // };

  const [sendFeedback, { isLoading }] = useLazySendFeedbackQuery();
  const defaultValues: FormInfoI = {
    description: '',
    email: userEmail ?? '',
    type: 'issue',
  };
  const formInfo = yup.object().shape({
    email: yup
      .string()
      .email('This field is invalid')
      .max(255)
      .matches(/^[a-zA-Z0-9._%+-]+@\w+\.[a-zA-Z]{2,}$/, 'Please enter a valid email address.'),
    description: yup.string(),
  });
  const accountForm = useForm({
    mode: 'all',
    defaultValues: defaultValues,
    resolver: yupResolver(formInfo),
  });
  const { reset } = accountForm;
  const handleSubmit = async (values: any) => {
    const copyValues = { ...values };
    copyValues.email = values.email.toLowerCase();
    await sendFeedback(copyValues).unwrap();
    reset();
  };
  const typeOptions = [
    {
      title: 'Issue',
      value: 'issue',
    },

    {
      title: 'Feedback',
      value: 'feedback',
    },
  ];

  return (
    <FormProvider {...accountForm}>
      <StyledForm onSubmit={accountForm.handleSubmit(handleSubmit)}>
        <Box
          sx={{
            opacity: 1,
            height: '100%',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: '750px', p: 3 }}>
            <CustomTextInput size="small" name="email" label="Email address" />
            <CustomTextInput
              size="small"
              name="type"
              label=" Choose type"
              select={true}
              // selectedValue={accountForm.values.type}
            >
              {typeOptions.map((item) => {
                return (
                  <MenuItem key={item.title} value={item.value}>
                    {item.title}
                  </MenuItem>
                );
              })}
              {/* <MenuItem value={1}>Issue</MenuItem>
              <MenuItem value={2}>Assue</MenuItem>
              <MenuItem value={3}>Feedback</MenuItem> */}
            </CustomTextInput>

            <CustomTextInput size="small" name="description" label="Description" multiline={true} rows={3} />
            <StyledFooter>
              <Button sx={{ width: '375px' }} type={'submit'} variant={'contained'}>
                Send
              </Button>
            </StyledFooter>
          </Box>
        </Box>
      </StyledForm>
    </FormProvider>
  );
};

export default Help;
