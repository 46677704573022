import { TextField, Paper, Box, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
export const SearchInput = styled(TextField)(({ theme }) => ({
  borderRadius: '4px',
  fontFamily: 'Roboto',
  background: 'white',
  width: '100%',
  border: '1px solid, gray',
  marginBottom: theme.spacing(2),
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    '>div': {
      height: '48px',
    },
  },
})) as typeof TextField;

export const MenuPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(0, 0, 2, 0),
  // borderRadius: '4px',
  boxShadow: 'none',
})) as typeof Paper;

export const MenuPaperContainer = styled(Box)(({}) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
  justifyContent: 'space-between',
})) as typeof Box;

export const MenuPaperTexts = styled(Box)(({}) => ({
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
})) as typeof Box;

export const MenuPaperListContent = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'column',
})) as typeof Box;

export const MenuPaperListItemTitle = styled(ListItemText)(({}) => ({
  '> span': {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
  },
})) as typeof ListItemText;

export const MenuPaperListItemDescription = styled(ListItemText)(({}) => ({
  '> span': {
    fontSize: '16px',
    fontWeight: 400,
    color: '#8E8E93',
  },
})) as typeof ListItemText;

export const CategoryPaperListWrapper = styled(Box)(({}) => ({
  display: 'flex',
  gap: '32px',
  alignItems: 'center',
  '> img': {
    width: '64px',
    height: '64px',
    borderRadius: '4px',
  },
})) as typeof Box;

export const CategoryPaperListCountWrapper = styled(Box)(({}) => ({
  display: 'flex',
  alignItems: 'center',
})) as typeof Box;
