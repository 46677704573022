/**
 * Show prices as decimals or as ints depending on the currency
 */
export function isIntCurrency(currencyCode?: string) {
  if (currencyCode?.includes('AMD')) {
    return true;
  }

  return false;
}
