import React, {useState} from 'react';
import {
  CategoryPaperListCountWrapper,
  CategoryPaperListWrapper,
  MenuPaper,
  MenuPaperContainer,
  MenuPaperListContent,
  MenuPaperTexts,
  ProductTitle,
} from 'pages/Overview/Menu/Items/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Box, Popover, Typography, IconButton} from '@mui/material';
import PopoverContent from 'components/UI/PopoverContent/PopoverContent';
import ConfirmableModal from 'components/Modals/ConfirmableModal';
import {configSelectors} from 'features/config';
import {useAppSelector} from 'hooks/useReduxHook';
import {formatPriceIntOrFloat} from 'utils/formatPriceInput';
import {useNavigate} from 'react-router-dom';
// import Preloader from 'components/UI/Preloader/Preloader';
import {IPictureCategory} from 'components/Categories';
import {useMatchMobile, useMatchTablet} from 'hooks/useMatchMobile';
// import Preloader from 'components/UI/Preloader/Preloader';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface ISortableDraggableMenuItemProps {
  item: ItemFullDTO;
  currentMenuName: string;
  deleteItem: any;
}

const Item = ({ item, currentMenuName, deleteItem }: ISortableDraggableMenuItemProps) => {
  const currency = useAppSelector(configSelectors.selectedCurrency);
  const { isMatchTablet } = useMatchTablet();
  const { isMatchMobile } = useMatchMobile();
  const { name, id, pictures, price, priceRange, status } = item;
  const navigate = useNavigate();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => setAnchorEl(null);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const defaultImage = pictures?.find((item: IPictureCategory) => item.default);
  const open = Boolean(anchorEl);
  const ids = open ? 'simple-popover' : undefined;

  const handleEdit = () => {
    navigate(`/items/edit?id=${id}`);
  };

  const handleDelete = () => {
    handleClose();
    setOpenConfirmModal(true);
  };

  const handleConfirm = async () => {
    setOpenConfirmModal(false);
    deleteItem(id);
  };

  const priceRangeText = () => {
    if (!currency) return '';
    if (!price?.length) {
      return '';
    }
    if (price?.length === 1) {
      return `${currency.symbol} ${formatPriceIntOrFloat(price[0].count, currency.currencyCode)}`;
    }
    if(isMatchMobile) {
      return `${currency.symbol} ${formatPriceIntOrFloat(priceRange?.min, currency.currencyCode)} -`;
    }else {
      return `${currency.symbol} ${formatPriceIntOrFloat(priceRange?.min, currency.currencyCode)} -
      ${currency.symbol} ${formatPriceIntOrFloat(priceRange?.max, currency.currencyCode)}`;
    }
  };

  const convertName = (name: string): string => {
    if(!isMatchMobile) {
      return name
    }
    return name.length >= 6 ? `${name.slice(0, 6)}....` : name
  }

  return (
    <MenuPaper sx={{ pl: 2, cursor: 'pointer' }}>
      <MenuPaperContainer onClick={handleEdit}>
        <MenuPaperTexts>
          <MenuPaperListContent>
            <CategoryPaperListWrapper>
              {defaultImage && defaultImage?.url && (
                <Box sx={{ width: '64px', height: '64px', opacity: !status ? 0.4 : 1 }}>
                  {<img src={`${defaultImage?.url}?tr=w-64,h-64,q-100,bg-F0F0F0`} alt={name || ''} />}
                </Box>
              )}

              <Box sx={{ opacity: !status ? 0.4 : 1 }}>
                <ProductTitle>{convertName(name)}</ProductTitle>
                {!isMatchTablet && (
                  <Typography
                    sx={{ fontSize: '14px', color: '#3C3C43', alignItems: 'center', display: 'flex', mt: 0.5 }}
                  >
                    {`Used in ${currentMenuName} menu`} <ArrowForwardIcon sx={{ color: '#3C3C43', fontSize: '20px' }} />{' '}
                    {item.categoryName}
                  </Typography>
                )}
                {!status && (
                  <Typography sx={{ fontSize: '14px', color: '#3C3C43' }}>Disabled</Typography>
                )}
              </Box>
            </CategoryPaperListWrapper>
          </MenuPaperListContent>
        </MenuPaperTexts>
        <CategoryPaperListCountWrapper>
          <Typography
            sx={{
              // width: 'max-content',
              opacity: !status ? 0.4 : 1,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'block',
              overflow: 'hidden',
              width: isMatchTablet ? '120px' : 'max-content',
              textAlign: 'right',
            }}
          >
            {`${priceRangeText()}`} <br/>
            {price?.length !== 1 && isMatchMobile ?  (
              <span>{`${currency.symbol} ${formatPriceIntOrFloat(priceRange?.max, currency.currencyCode)}`} &nbsp;</span>
            ) : ("")}
          </Typography>
          <IconButton onClick={handleClick} sx={{ pr: 0 }} disableRipple disableTouchRipple>
            <MoreVertIcon />
          </IconButton>
        </CategoryPaperListCountWrapper>
      </MenuPaperContainer>
      {isMatchTablet && (
        <Typography sx={{ fontSize: '14px', color: '#3C3C43', alignItems: 'center', display: 'flex', mt: 0.5 }}>
          {`Used in ${currentMenuName} menu`} <ArrowForwardIcon sx={{ color: '#3C3C43', fontSize: '20px' }} />{' '}
          {item.categoryName}
        </Typography>
      )}
      <Popover
        id={ids}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContent
          editAction={'Edit'}
          deleteAction={'Delete'}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </Popover>
      <ConfirmableModal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirm}
        title={'Delete'}
        message={'Are you sure you want to delete this item?'}
      />
    </MenuPaper>
  );
};

export default Item;
