import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'features';
import { api } from 'api';

type AuthState = {
  user: UserDTO | null;
  isChecked: boolean;
};

// Define the initial state using that type
const initialState: AuthState = {
  user: null,
  isChecked: false,
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserCheck(state, action) {
      state.isChecked = action.payload;
    },
    loginFromAsyncStorage(state, action) {
      state.user = action.payload;
    },
    logout(state) {
      // state.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('fileId');
      localStorage.removeItem('categoryFileId');
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/');
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.signIn.matchFulfilled, (state, { payload }) => {
      state.user = payload;
      localStorage.setItem('user', JSON.stringify(payload));
    });
    builder.addMatcher(api.endpoints.signUp.matchFulfilled, (state, { payload }) => {
      state.user = payload;
      localStorage.setItem('user', JSON.stringify(payload));
    });
    builder.addMatcher(api.endpoints.googleRedirect.matchFulfilled, (state, { payload }) => {
      state.user = payload;

      try {
        localStorage.setItem('user', JSON.stringify(payload));
      } catch (error) {
        console.log('Error');
      }
    });
    builder.addMatcher(api.endpoints.appleRedirect.matchFulfilled, (state, { payload }) => {
      state.user = payload;

      try {
        localStorage.setItem('user', JSON.stringify(payload));
      } catch (error) {
        console.log((error as Error)?.message || 'Error');
      }
    });
  },
});

export const { loginFromAsyncStorage } = authSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const authSelectors = {
  user: (state: RootState) => state.auth.user,
  isUserChecked: (state: RootState) => state.auth.isChecked,
};

export default authSlice;
