import React, { useState } from 'react';
import { Box, Link, Typography, Button } from '@mui/material';
import GoBack from './GoBack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthBox, AuthSubTitle, AuthTitle, StyledForm, StyledFormBox } from './styles';
import CustomTextInput from 'components/FormFields/CustomTextInput';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useLazyCheckEmailQuery,
  useLazyForgotPasswordConfirmQuery,
  useLazyForgotPasswordQuery,
  useLazyResendVerifyEmailQuery,
  useLazyVerifyEmailQuery,
} from 'api';
import CountdownTimer from './CountdownTimer';
import { IVerifyEmail } from './types';
import LoadingButton from 'components/UI/LoadingButton';

function getMaskedEmail(email?: string): string {
  if (!email) return '';

  const emailParts = email.split('@');

  const maskedPart =
    emailParts[0].length > 2
      ? emailParts[0].charAt(0) + '*'.repeat(emailParts[0].length - 2) + emailParts[0].slice(-1)
      : '*'.repeat(emailParts[0].length);

  return maskedPart + '@' + emailParts[1];
}

const schemaVerifyEmail = yup
  .object()
  .shape({
    email: yup.string().required('This field is required'),
    confirmationCode: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .required('This field is required'),
  })
  .required();

const numberInputPropsStyles = {
  input: {
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
};

const EmailVerification: any = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const maskedEmail = getMaskedEmail(state?.email);
  const [resetDuration, setResetDuration] = useState<boolean>(false);
  const [timerFinished, setTimerFinished] = useState<boolean>(false);
  const [checkEmail, { isLoading }] = useLazyCheckEmailQuery();
  const [verifyForgotPassword, { isLoading: isForgotPasswordLoading }] = useLazyForgotPasswordConfirmQuery();
  const [resendVerify, { isLoading: isResendLoading }] = useLazyResendVerifyEmailQuery();
  const [resendForgotPassword, { isLoading: isResendForgotPasswordLoading }] = useLazyForgotPasswordQuery();
  const [verifyEmail, { isLoading: isVerifyLoading }] = useLazyVerifyEmailQuery();

  const defaultValuesEmailVerify: IVerifyEmail = {
    email: state?.email,
    confirmationCode: '',
  };

  const verifyEmailFormMethods = useForm({
    mode: 'all',
    defaultValues: defaultValuesEmailVerify,
    resolver: yupResolver(schemaVerifyEmail),
  });

  const { handleSubmit, getValues, reset } = verifyEmailFormMethods;
  const formData = getValues();

  const onSubmit = async (data: IVerifyEmail) => {
    // Need to discuss backend why data.confirmationCode accepts only string
    data.confirmationCode = data.confirmationCode.toString();
    try {
      if (state.isNewUser) {
        await verifyEmail(data).unwrap();
        navigate('/auth/createPassword', { state: { email: data.email } });
      } else {
        await verifyForgotPassword(data).unwrap();
        navigate('/auth/passwordResetNew', { state: { email: data.email } });
      }
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
  };

  const onError = (errors: any) => {
    console.log(errors);
  };

  const onTimerEnd = () => {
    setTimerFinished(true);
  };

  const handleResetTimer = async () => {
    if (state?.email) {
      try {
        state?.isNewUser ? await resendVerify(state?.email).unwrap() : await resendForgotPassword(state?.email);
        reset();
        setResetDuration(!resetDuration);
        setTimeout(() => {
          setTimerFinished(false);
        }, 300);
      } catch (err) {
        console.log('err: ', err);
      }
    }
  };

  const handleChangeEmail = () => {
    navigate(-1);
  };

  return (
    <Box>
      <AuthBox>
        <GoBack />
        <AuthTitle variant="h1">Verification</AuthTitle>

        <AuthSubTitle>
          We have sent verification code to your email address {maskedEmail}{' '}
          <Link component="button" onClick={handleChangeEmail}>
            Change
          </Link>
        </AuthSubTitle>
        <StyledFormBox>
          <FormProvider {...verifyEmailFormMethods}>
            <StyledForm onSubmit={handleSubmit(onSubmit, onError)}>
              <Box>
                <CustomTextInput
                  InputProps={{
                    type: 'number',
                  }}
                  label="Verification code"
                  name="confirmationCode"
                  sx={{ ...numberInputPropsStyles, mt: 5, width: '100%' }}
                />
                <CustomTextInput name="email" sx={{ display: 'none' }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, alignItems: 'center' }}>
                  <Button disabled={!timerFinished} sx={{ color: 'black' }} onClick={handleResetTimer}>
                    <Typography
                      sx={{ fontSize: '16px', color: timerFinished ? '#007DFF' : '#283532CC' }}
                      fontWeight={'bold'}
                    >
                      {timerFinished ? 'Resend' : 'Resend code in'}
                    </Typography>
                  </Button>
                  <CountdownTimer duration={60} onTimerEnd={onTimerEnd} resetDuration={resetDuration} />
                </Box>
              </Box>
              <LoadingButton isLoading={isLoading} type="submit" sx={{ width: '100%' }} variant="contained">
                Verify
              </LoadingButton>
            </StyledForm>
          </FormProvider>
        </StyledFormBox>
      </AuthBox>
    </Box>
  );
};

export default EmailVerification;
