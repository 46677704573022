import React from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ViewListIcon from '@mui/icons-material/ViewList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';

export const MENU_LIST = [
  {
    name: 'Overview',
    MenuIcon: <MenuBookIcon />,
    path: '/overview',
    breadcrumbPath: '/overview',
    breadcrumbName: 'Overview',
    show: true,
    backIcon: false,
    isActiveKey: 'overviewIsActive',
  },
  // {
  //   name: 'Menus',
  //   MenuIcon: <RestaurantMenuIcon />,
  //   path: '/menus',
  //   breadcrumbPath: '/menus',
  //   breadcrumbName: 'Menus',
  //   show: true,
  //   backIcon: false,
  //   isActiveKey: 'menusisActive',
  // },
  // {
  //   name: 'Create Menu',
  //   MenuIcon: <RestaurantMenuIcon />,
  //   path: '/menus/create',
  //   breadcrumbPath: '/menus/create',
  //   breadcrumbName: 'New menu',
  //   show: false,
  //   backIcon: true,
  //   isActiveKey: 'menuCreateIsActive',
  // },
  // {
  //   name: 'Update Menu',
  //   MenuIcon: <RestaurantMenuIcon />,
  //   path: '/menus/edit',
  //   breadcrumbPath: '/menus/edit',
  //   breadcrumbName: 'Update menu',
  //   show: false,
  //   backIcon: true,
  //   isActiveKey: 'menuUpdateIsActive',
  // },
  {
    name: 'Categories',
    MenuIcon: <ViewListIcon />,
    path: '/categories',
    breadcrumbPath: '/categories',
    breadcrumbName: 'Categories',
    show: true,
    backIcon: false,
    isActiveKey: 'categoriesIsActive',
  },
  {
    name: 'Create Categories',
    MenuIcon: <RestaurantMenuIcon />,
    path: '/categories/create',
    breadcrumbPath: '/categories/create',
    breadcrumbName: 'New category',
    show: false,
    backIcon: true,
    isActiveKey: 'categoryCreateIsActive',
  },
  {
    name: 'Update categories',
    MenuIcon: <RestaurantMenuIcon />,
    path: '/categories/edit',
    breadcrumbPath: '/categories/edit',
    breadcrumbName: 'Update category',
    show: false,
    backIcon: true,
    isActiveKey: 'categoryUpdateIsActive',
  },
  {
    name: 'Items',
    MenuIcon: <ListAltIcon />,
    path: '/items',
    breadcrumbPath: '/items',
    breadcrumbName: 'Items',
    show: true,
    backIcon: false,
    isActiveKey: 'itemsIsActive',
  },
  {
    name: 'Create Item',
    MenuIcon: <ListAltIcon />,
    path: '/items/create',
    breadcrumbPath: '/items/create',
    breadcrumbName: 'New item',
    show: false,
    backIcon: true,
    isActiveKey: 'itemsIsActive',
  },
  {
    name: 'Update item',
    MenuIcon: <ListAltIcon />,
    path: '/items/edit',
    breadcrumbPath: '/items/edit',
    breadcrumbName: 'Update item',
    show: false,
    backIcon: true,
    isActiveKey: 'itemsIsActive',
  },
  {
    name: 'My account',
    MenuIcon: <PersonIcon />,
    path: '/my-account',
    breadcrumbPath: '/my-account',
    breadcrumbName: 'My account',
    show: true,
    backIcon: false,
    isActiveKey: 'accountIsActive',
  },
  {
    name: 'Business Profile',
    MenuIcon: <BusinessCenterIcon />,
    path: '/business-profile',
    breadcrumbPath: '/business-profile',
    breadcrumbName: 'Business profile',
    show: true,
    backIcon: false,
    isActiveKey: 'businessIsActive',
  },
  {
    name: 'Website Settings',
    MenuIcon: <SettingsIcon />,
    path: '/website-settings',
    breadcrumbPath: '/website-settings',
    breadcrumbName: 'Website settings',
    show: true,
    backIcon: false,
    isActiveKey: 'websiteSettingsIsActive',
  },
  {
    name: 'Help',
    MenuIcon: <HelpIcon />,
    path: '/help',
    breadcrumbPath: '/help',
    breadcrumbName: 'Help',
    show: true,
    backIcon: false,
    isActiveKey: 'helpIsActive',
  },
  {
    name: 'Log out',
    MenuIcon: <LogoutIcon />,
    path: '/logout',
    breadcrumbPath: '/logout',
    breadcrumbName: 'Log out',
    show: true,
    backIcon: false,
    isActiveKey: 'logoutIsActive',
  },
];
