import { Box, styled, FormControl } from '@mui/material';

export const SettingsBoxRadio = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginRight: '40px',
});

export const SettingsBoxRadioDark = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});
export const StyledFooterSettings = styled(Box)(({ theme }) => ({
  width: '100%',
  // background: 'white',
  // boxShadow: '0px 0px 6px #B9B9B929',
  marginTop: `${theme.spacing(3)}`,
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '280px',
  },
}));
