import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'features';

export const baseQuery = fetchBaseQuery({
  baseUrl: 'https://z01.orders.co/v1/api',
  prepareHeaders: (headers, { getState }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.user?.jwt || user?.jwt;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
