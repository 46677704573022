import React, { useEffect, useState } from 'react';
import { isMobile, isIOS } from 'react-device-detect';
import { Box, Button, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HeaderLogo from 'assets/img/logo/orders_LogoAPP.png';
import { useCookies } from 'react-cookie';

const MobileDownloadBanner = () => {
  const [cookies, setCookie] = useCookies(['isShowBanner']);
  const [showBanner, setShowBanner] = useState(!Boolean(cookies.isShowBanner));

  useEffect(() => {
    if (!Boolean(cookies.isShowBanner)) {
      setCookie('isShowBanner', true);
    }
  }, []);

  const closeBanner = () => {
    setShowBanner(false);
    setCookie('isShowBanner', false);
  };

  if (!isMobile || !showBanner) {
    return null;
  }

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 999,
        width: '100%',
        padding: '10px',
        textAlign: 'center',
        background: '#f0f0f0',
      }}
    >
      <Box sx={{ display: 'flex', '& img': { width: '30px' }, alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={closeBanner} sx={{ p: 0, mr: 2 }}>
            <CloseIcon />
          </IconButton>
          <img src={HeaderLogo} alt={'Qr logo'} />
        </Box>
        <p>Download mobile app.</p>
        {isIOS ? (
          <Button
            variant="contained"
            color="primary"
            href="https://apps.apple.com/us/app/qr-menu-maker/id1643857790"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            href="https://play.google.com/store/apps/details?id=com.qrmenumaker"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default MobileDownloadBanner;
