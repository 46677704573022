import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './useReduxHook';
import { useLazyGetCurrenciesQuery, useLazyGetSettingsQuery } from 'api';
import { authSelectors } from 'features/auth';
import configSlice from 'features/config';

/**
 * Set selected currency out of the list of currencies list
 */
export function useCurrency() {
  const user = useAppSelector(authSelectors.user);

  const [getSettings] = useLazyGetSettingsQuery(undefined);
  const [getCurrencies] = useLazyGetCurrenciesQuery(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      const promises: [Promise<WebsiteSettingsDTO>, Promise<CurrencyDTO[]>] = [
        getSettings(undefined).unwrap(),
        getCurrencies(undefined).unwrap(),
      ];

      Promise.all(promises).then(([settings, currencies]) => {
        const selectedCurrency = currencies?.find((currency) => currency?.id === settings?.menuCurrencyId);

        if (selectedCurrency) {
          dispatch(configSlice.actions.setSelectedCurrency(selectedCurrency));
        }
      });
    }
  }, [user]);
}
