import React from 'react';
import { List } from '@mui/material';
import { SortableContainer } from 'react-sortable-hoc';
import SortableDraggbleCategoryList from './SortableDraggbleListItem';

interface ISortableContainerProps {
  items: string[] | any;
  onSortEnd: (oldIndex: number, newIndex: number) => void;
  currentMenuId: string;
}

const SortableList = SortableContainer<ISortableContainerProps>(({ items, currentMenuId }: ISortableContainerProps) => {
  return (
    <List>
      {items.map((value: any, index: any) => {
        return (
          <SortableDraggbleCategoryList
            key={value.id}
            index={index}
            name={value?.name}
            menuId={currentMenuId}
            id={value.id}
            list={value.products || []}
            status={value?.status}
          />
        )
      })}
    </List>
  );
});

export default SortableList;
