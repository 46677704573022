import { Box, styled } from '@mui/material';

export const MyAccountBox = styled(Box)(({ theme }) =>({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  }
}));

export const MyAccountContentBox = styled(Box)(({ theme }) =>({
  height: '46px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap'
  }
}));
