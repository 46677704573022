import React from 'react';
import SidebarDrawer from 'components/SidebarDrawer';
import { useLocation } from 'react-router-dom';
import PasswordChange from 'pages/PasswordChange/index';

function Main() {
  const location = useLocation();

  if (location.pathname === '/changePassword') {
    return <PasswordChange />;
  }
  return <SidebarDrawer />;
}

export default Main;
