import { Box, Typography, Popover } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import OutlinedFlagTwoToneIcon from '@mui/icons-material/OutlinedFlagTwoTone';

const PopoverProduct = (props: any) => {
  const { _id, anchorEl, open, handleClose, handleDelete, handleDefault } = props;
  return (
    <Popover
      id={_id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
    >
      <Box
        sx={{
          width: '300px',
          height: '140px',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '3px',
          flexDirection: 'column',
        }}
      >
        <Box
          onClick={handleDefault}
          sx={{
            cursor: 'pointer',
            width: '100%',
            display: 'flex',
            flexDirection: 'row ',
            ml: '17px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OutlinedFlagTwoToneIcon sx={{ fontSize: '22px', zIndex: 5, color: 'black', mr: '15px' }} />
          <Typography sx={{ width: '100%' }}>Set as Default</Typography>
        </Box>
        <Box className="line"></Box>
        <Box sx={{ color: 'red', ml: '17px', mt: '12px', display: 'flex', cursor: 'pointer' }}>
          {/* <DeleteIcon sx={{ mr: '15px' }} variant="contained" size="sm" onClick={handleDelete}>
            <i className="icon icon-trash-2"></i>
          </DeleteIcon> */}
          <Typography onClick={handleDelete}>Delete</Typography>
        </Box>
      </Box>
    </Popover>
  );
};
export default PopoverProduct;
