import React from 'react';
import { Box, Button } from '@mui/material';
import GoBack from '../GoBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthBox, AuthSubTitle, StyledFormBox } from '../styles';

const SuccessStep = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmit = () => {
    navigate('/auth/enterPassword', { state: { email: state?.email } });
  };

  return (
    <Box>
      <AuthBox>
        <GoBack />
        {/* <AuthTitle variant="h1">I’m Ready</AuthTitle> */}
        <AuthSubTitle>Your New Password has been successfully saved!</AuthSubTitle>
        <StyledFormBox>
          <Box />
          <Button onClick={onSubmit} type="submit" sx={{ width: '100%' }} variant="contained">
            I’m Ready
          </Button>
        </StyledFormBox>
      </AuthBox>
    </Box>
  );
};

export default SuccessStep;
