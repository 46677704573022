import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { StyledOverviewBanner } from './styles';
import { WelcomePage } from 'components/UI/WelcomePage';

const OverviewWelcome = () => {
  return (
    <Box sx={{ px: 8, py: 4 }}>
      <Grid container>
        <Grid item md={12}>
          <StyledOverviewBanner>
            <Typography sx={{ fontSize: '32px', color: 'white', fontWeight: '500' }} variant="h1">
              Welcome to
            </Typography>
            <Typography sx={{ fontSize: '20px', color: 'white', mt: 1 }}>Orders.co web</Typography>
          </StyledOverviewBanner>
          <WelcomePage
            description={
              'To get started, first you need to create your menu, the second step is to create a category and then a item.'
            }
            url={'/menus/create'}
            title={'Menus will appear here'}
            buttonText="Start creating menu"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewWelcome;
