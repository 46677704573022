import React, { useState } from 'react';
import DragImage from 'assets/img/drag.png';
import {
  MenuPaper,
  MenuPaperTexts,
  MenuPaperContainer,
  MenuPaperListContent,
  CategoryPaperListWrapper,
  CategoryPaperListCountWrapper,
} from './styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Icon, Box, Typography, Popover, IconButton } from '@mui/material';
import PopoverContent from 'components/UI/PopoverContent/PopoverContent';
import ConfirmableModal from 'components/Modals/ConfirmableModal';
import { useDeleteItemMutation } from 'api';
import { configSelectors } from 'features/config';
import { useAppSelector } from 'hooks/useReduxHook';
import { formatPriceIntOrFloat } from 'utils/formatPriceInput';
import { useNavigate } from 'react-router-dom';
import Preloader from 'components/UI/Preloader/Preloader';
import theme from 'theme';
import { IPictureCategory } from 'components/Categories';
import {useMatchMobile, useMatchTablet} from 'hooks/useMatchMobile';
// import Preloader from 'components/UI/Preloader/Preloader';

interface ISortableDraggableMenuItemProps {
  item: ItemFullDTO;
  showBorder: boolean;
}
const DragHandle = SortableHandle(() => {
  return <img src={DragImage} alt={'drag'} style={{ width: '24px', height: '24px', cursor: 'grab' }} />;
});

const SortableDraggbleListItem = SortableElement<any>(({ item, showBorder }: ISortableDraggableMenuItemProps) => {
  const currency = useAppSelector(configSelectors.selectedCurrency);
  const { isMatchTablet } = useMatchTablet();
  const { isMatchMobile } = useMatchMobile();
  const { name, id, pictures, price, priceRange, status } = item;
  const navigate = useNavigate();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [deleteItem, { isLoading: isDeleteLoading }] = useDeleteItemMutation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const defaultImage = pictures?.find((item: IPictureCategory) => item.default);
  const open = Boolean(anchorEl);
  const ids = open ? 'simple-popover' : undefined;

  const handleEdit = () => {
    navigate(`/items/edit?id=${id}`);
  };

  const handleDelete = () => {
    handleClose();
    setOpenConfirmModal(true);
  };

  const handleConfirm = async () => {
    setOpenConfirmModal(false);
    await deleteItem(id).unwrap();
    window.location.reload();
  };

  const priceRangeText = () => {
    if (!currency) return '';
    if (!price?.length) {
      return '';
    }
    if (price?.length === 1) {
      return `${currency.symbol} ${formatPriceIntOrFloat(price[0].count, currency.currencyCode)}`;
    }
    if(isMatchMobile) {
      return `${currency.symbol} ${formatPriceIntOrFloat(priceRange?.min, currency.currencyCode)} -`;
    }else {
      return `${currency.symbol} ${formatPriceIntOrFloat(priceRange?.min, currency.currencyCode)} -
      ${currency.symbol} ${formatPriceIntOrFloat(priceRange?.max, currency.currencyCode)}`;
    }
  };

  const convertName = (name: string): string => {
    if(!isMatchMobile) {
      return name
    }
    return name.length >= 6 ? `${name.slice(0, 6)}....` : name
  }

  return (
    <MenuPaper
      sx={{ borderBottom: showBorder ? `1px solid ${theme.palette.divider}` : 'none' }}
    >
      <MenuPaperContainer>
        {isDeleteLoading && <Preloader sx={{ margin: 0 }} overlay={true} />}
        <MenuPaperTexts sx={{ opacity: !status ? 0.5 : 1 }}>
          <Icon sx={{ color: '#9E9EAD' }} component={DragHandle} />
          <MenuPaperListContent>
            <CategoryPaperListWrapper>
              {defaultImage && defaultImage?.url && (
                <Box sx={{ width: '64px', height: '64px' }}>{<img src={defaultImage?.url} alt={name || ''} />}</Box>
              )}

              <Box>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#3C3C43' }}>{convertName(name)}</Typography>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    overflow: 'hidden',
                    width: 'max-content',
                    textAlign: 'right',
                  }}
                >
                  {`${priceRangeText()}`} <br/>
                  {price?.length !== 1 && isMatchMobile ?  (
                    <span>{`${currency.symbol} ${formatPriceIntOrFloat(priceRange?.max, currency.currencyCode)}`} &nbsp;</span>
                  ) : ("")}
                </Typography>
                {!status && (
                  <Typography sx={{ fontSize: '14px', color: '#3C3C43' }}>Disabled</Typography>
                )}
              </Box>
            </CategoryPaperListWrapper>
          </MenuPaperListContent>
        </MenuPaperTexts>
        <CategoryPaperListCountWrapper>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </CategoryPaperListCountWrapper>
      </MenuPaperContainer>

      <Popover
        id={ids}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContent
          editAction={'Edit'}
          deleteAction={'Delete'}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </Popover>
      <ConfirmableModal
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirm}
        title={'Delete'}
        message={'Are you sure you want to delete this item?'}
      />
    </MenuPaper>
  );
});

export default SortableDraggbleListItem;
